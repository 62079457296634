import {
	ErrorResponse,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

export class ReportService
{
	public static sendReportEntry(
		token: string,
		opDescription: string,
		onReportSent: ((response: RestResponse<void, ErrorResponse>) => void)|null = null,
		onReportProblemError: (() => void)|null = null,
	): void
	{
		const url = `/api/process/report`
		const data = {
			"processToken": token,
			"data": new Date().toISOString(),
			"feVersion": process.env.REACT_APP_VERSION,
			"opDescription": opDescription,
		}

		RestService
			.post<void>(url, data)
			.then(onReportSent)
			.catch(onReportProblemError)
	}
}
