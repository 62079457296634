import React from "react"

import {
	Paper,
	Stepper,
} from "@mui/material"

import {
	ErrorResponse,
	Loading,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	CallbackUrls,
	LocalStorageEntries,
	SignatureInfo,
	SignatureMode,
} from "common-models"
import withUseRouter, { UseRouterParams } from "services/router"
import {
	BaseStepProps,
	ClausesStep,
	DownloadDocumentStep,
	OtpStep,
	UploadStep,
} from "components/sign/steps"
import { AbortProcessMode } from "components/sign/steps/base-step"
import { AbortProcessModal } from "components/abort-process-modal/abort-process-modal"
import { ReportService } from "services/report.service"

export interface SignProps extends UseRouterParams
{
}
export interface SignState
{
	loading: boolean
	step: number
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
	openAbortProcessModal: boolean
}
class Sign extends React.Component<SignProps, SignState>
{
	private token: string = LocalStorageEntries.readToken()!

	private signatureMode: SignatureMode = LocalStorageEntries.readSignatureMode()!

	constructor(props: SignProps)
	{
		super(props)
		this.state = {
			loading: false,
			step: 0,
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
			openAbortProcessModal: false,
		}
	}

	private onStepCompleted = (): void => {
		this.setState(() => ({ step: this.state.step + 1 }))
	}

	private terminateProcess = (mode: AbortProcessMode): void => {
		if(mode === AbortProcessMode.REQUIRE_CONFIRM) {
			ReportService.sendReportEntry(this.token, "Aperta modal di conferma interruzione processo di firma")
			this.setState(() => ({ openAbortProcessModal: true, }))
			return
		}

		this.showLoading()
		ReportService.sendReportEntry(this.token, "Interruzione processo di firma...")

		let url = `/api/process/${this.token}`
		this.setState(() => ({
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}))
		RestService
			.delete<SignatureInfo>(url)
			.then(this.onProcessAbortSuccess)
			.catch(this.onProcessAbortFailed)
	}

	private onProcessAbortSuccess = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onProcessAbortFailed()
			return
		}

		ReportService.sendReportEntry(this.token, "Processo interrotto")

		this.hideLoading()
		this.goToCancelCallback()
	}

	private goToCancelCallback = (): void => {
		const callbackUrls: CallbackUrls = LocalStorageEntries.readCallbackUrls()!
		window.location.href = callbackUrls.cancelUrl
	}

	private onProcessAbortCancelled = (): void => {
		ReportService.sendReportEntry(this.token, "Annullata interruzione processo di firma")
		this.setState(() => ({ openAbortProcessModal: false, }))
	}

	private onProcessAbortFailed = (): void => {
		ReportService.sendReportEntry(this.token, "Interruzione processo di firma fallita")
		this.hideLoading()
		this.setState(() => ({
			openAbortProcessModal: false,
			errorDialog: {
				open: false,
				title: "Errore", // FIXME: tradurre
				message: "Non è stato possibile procedere con l'interruzione del processo. Si prega di riprovare.", // FIXME: tradurre
			}
		}))
	}

	private showLoading = (): void => {
		this.setState(() => ({loading: true}))
	}

	private hideLoading = (): void => {
		this.setState(() => ({loading: false}))
	}

	private getCommonStepArguments = (index: number): BaseStepProps => {
		return {
			index,
			abortProcess: this.terminateProcess,
			stepCompleted: this.onStepCompleted,
			showLoading: this.showLoading,
			hideLoading: this.hideLoading,
		} as BaseStepProps
	}

	render()
	{
		return (
			<>
				<Loading show={this.state.loading} />
				<AbortProcessModal
					openModal={this.state.openAbortProcessModal}
					onConfirm={this.goToCancelCallback}
					onCancel={this.onProcessAbortCancelled}
					onFailure={this.onProcessAbortFailed} />

				<Paper sx={{p: 3}}>
					<Stepper orientation="vertical" activeStep={this.state.step}>
						{this.signatureMode === SignatureMode.INTERNAL &&
							<>
								<ClausesStep {...this.getCommonStepArguments(0)} />
								<OtpStep {...this.getCommonStepArguments(1)} />
							</>
						}
						{this.signatureMode !== SignatureMode.INTERNAL &&
							<>
								<DownloadDocumentStep {...this.getCommonStepArguments(0)} />
								<UploadStep {...this.getCommonStepArguments(1)} />
							</>
						}
					</Stepper>
				</Paper>
			</>
		)
	}
}

export default withUseRouter(Sign)
