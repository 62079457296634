import { i18n } from "@lingui/core"
import { TitleAndMessage } from "@sinossi/mates-react-library"
import { NavigateFunction } from "react-router-dom"

export enum ServerErrorCodes
{
	// GENERIC_ERROR = -1000,
	// FILE_NOT_FOUND = -1006,
	// CARONTE_NETWORK_ERROR = -1007,
	// BAD_REQUEST = -1008,
	GENERATION_ERROR = -1009,
	// GET_USER_ERROR = -1010,
	// UPDATE_USER_ERROR = -1011,
	// CREATE_USER_ERROR = -1012,
	// GENERATE_REQUEST_ID_ERROR = -1013,
	// INTERNAL_ERROR = -1016,
	// SEND_MAIL_ERROR = -1017,
	JSON_MARSHAL_ERROR = -1019,

	NO_SIGNATURES_FOUND = -3000,
	SIGNATURE_NOT_VALID = -3001,
	SIGN_ERROR = -3002,

	WRONG_OTP = -4000,
	WRONG_TOKEN = -4001,
	TOKEN_EXPIRED = -4002,
	// CLAUSE_STATUS_ERROR = -4003,
	// STATE_MACHINE_ERROR = -4004,
	CANNOT_SEND_OTP = -4005,
}

export enum InternalError
{
	NO_TOKEN_FOUND = "NO_TOKEN_FOUND",
	NO_SUCCESS_FOUND = "NO_SUCCESS_FOUND",
	NO_FAILURE_FOUND = "NO_FAILURE_FOUND",
	NO_CANCEL_FOUND = "NO_CANCEL_FOUND",
	INITIALIZATION_FAILED = "INITIALIZATION_FAILED",
	MISSING_REQUIRED_PARAMS = "MISSING_REQUIRED_PARAMS",
	PROCESS_ABORTED__NO_CALLBACK_URL = "PROCESS_ABORTED__NO_CALLBACK_URL",

	GENERIC_ERROR = "GENERIC_ERROR",
	WRONG_TOKEN = "WRONG_TOKEN",
	SIGNATURE_FAILED = "SIGNATURE_FAILED",
}

export class ErrorService
{
	public static defaultErrorConfig = {
		showErrorCode: true,
		options: {
			showErrorTimestamp: true,
			timestampFormat: "dd/MM/yyyy - HH:mm:ss.SSS",
			showCancel: false,
			okText: "OK",
			cancelText: "",
		},
	}

	private static errors = (code: number): string => {
		let errorList: Record<number, string> = {
			0: /*i18n*/i18n._("errors.cannot_contact_server"), // Server irraggiungibile
			0x1000: /*i18n*/i18n._("errors.generic_message"), // GENERIC_ERROR
			0x1010: /*i18n*/i18n._("errors.state_machine"), // STATE_MACHINE_ERROR
			0x1020: /*i18n*/i18n._("errors.validation_failed"), // VALIDATION_FAILED
			0x1030: /*i18n*/i18n._("errors.generic_message"), // NETWORK_ERROR
			1000: /*i18n*/i18n._("errors.generic_message"), // GENERIC_ERROR
			1006: "File non trovato",
			1007: "Errore caronte network",
			1008: "Bad request",
			1009: "Errore di generazione",
			1010: "Token inesistente",
			1014: "OTP errata",
			1015: "Token già utilizzato o scaduto",
			1019: "JSON marshal error",
			1020: "Errore di firma",
			1021: "Token scaduto",
			1100: "Errore di stato",
		}

		return errorList[code]
	}

	public static parseErrorCode(code: number): TitleAndMessage
	{
		return {
			title: /*i18n*/i18n._("errors.modal_title"),
			message: ErrorService.errors(code),
		}
	}

	public static openErrorPage(errorCodeFromServer: number, navigate: NavigateFunction): void
	{
		const errorCode: InternalError = ErrorService.internalErrorFromServerCode(errorCodeFromServer)
		ErrorService.openErrorPageWithInternalError(errorCode, navigate)
	}

	public static openErrorPageWithInternalError(errorCode: InternalError, navigate: NavigateFunction): void
	{
		navigate(
			"/error",
			{
				replace: true,
				state: {errorCode: errorCode, },
			},
		)
	}

	public static internalErrorFromServerCode(errorCode: number): InternalError
	{
		// non frontend errors:
		//   * ServerErrorCodes.UPDATE_USER_ERROR
		//   * ServerErrorCodes.CREATE_USER_ERROR
		//   * ServerErrorCodes.GENERATE_REQUEST_ID_ERROR
		//   * ServerErrorCodes.PDF_CONTAINS_INVALID_SIGNATURES
		//   * ServerErrorCodes.ALREADY_SIGNED
		switch(errorCode) {
			case ServerErrorCodes.WRONG_TOKEN:
			case ServerErrorCodes.TOKEN_EXPIRED:
				return InternalError.WRONG_TOKEN
			case ServerErrorCodes.JSON_MARSHAL_ERROR:
				return InternalError.SIGNATURE_FAILED
		}

		// Expected errors:
		// 	 * ServerErrorCodes.GENERIC_ERROR
		// 	 * ServerErrorCodes.FILE_NOT_FOUND
		// 	 * ServerErrorCodes.CARONTE_NETWORK_ERROR
		// 	 * ServerErrorCodes.BAD_REQUEST
		// 	 * ServerErrorCodes.GET_USER_ERROR
		// 	 * ServerErrorCodes.INTERNAL_ERROR
		// 	 * ServerErrorCodes.STATE_MACHINE_ERROR
		return InternalError.GENERIC_ERROR
	}
}
