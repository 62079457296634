import React from "react"

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Drawer,
	Typography,
	Box,
	Divider,
	IconButton,
} from "@mui/material"
import {
	ExpandMore,
	Close,
} from "@mui/icons-material"

import { Trans } from "@lingui/macro"

import StyledDrawerHeader from "structure/header/drawer-header"

import faqs from "./faq.json"

interface SidebarFaqProps
{
	openCloseDrawer: () => void
	drawerOpen: boolean
	drawerWidth: number
}
export class SidebarFaq extends React.Component<SidebarFaqProps, {}>
{
	render()
	{
		return (
			<Drawer
				variant="persistent"
				anchor="right"
				sx={{
					width: this.props.drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: {
						width: this.props.drawerWidth,
					},
				}}
				open={this.props.drawerOpen}
			>
				<StyledDrawerHeader sx={{ ml: 2, mr: 2 }}>
					<Typography variant="h4">
						<Trans id="sidebar-faq.title" comment="Title for FAQ sidebar">
							FAQ
						</Trans>
					</Typography>
					<Typography sx={{flexGrow: 1}} />

					<IconButton onClick={this.props.openCloseDrawer}>
						<Close />
					</IconButton>
				</StyledDrawerHeader>
				<Divider />

				<Box padding={2}>
					{faqs.map((it: any) =>
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography color="primary">{it.question}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>{it.answer}</Typography>
							</AccordionDetails>
						</Accordion>
					)}
				</Box>
			</Drawer>
		)
	}
}
