import React from 'react'

import {
	Box,
	Button,
	Typography,
} from "@mui/material"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import WarningIcon from '@mui/icons-material/Warning'

import { Link } from "react-router-dom"
import { Trans } from "@lingui/macro"

export class PageForbidden extends React.Component<{}, {}>
{
	render() {
		return (
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<WarningIcon color="error" sx={{ fontSize: 120 }} />
				<Typography mt={2} mb={5} variant="h3">
					<Trans id="errors.403.message" comment="403 Forbidden page message">Non hai i permessi per vedere questa pagina.</Trans>
				</Typography>

				<Link to="/">
					<Button variant="contained" startIcon={<ArrowBackIcon />}>
						<Trans id="buttons.back" comment="Back text on buttons">Indietro</Trans>
					</Button>
				</Link>
			</Box>
		)
	}
}
