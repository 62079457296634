import ReactDOMClient from "react-dom/client"
import reportWebVitals from "./reportWebVitals"

import { BrowserRouter } from "react-router-dom"

import { Worker } from "@react-pdf-viewer/core"

import AdapterLuxon from "@mui/lab/AdapterLuxon"
import LocalizationProvider from "@mui/lab/LocalizationProvider"

import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"

import { messages as messagesEn } from "./locales/en/messages"
import { messages as messagesIt } from "./locales/it/messages"

import { LocalStorageEntries } from "common-models"

import "index.scss"
import App from "./App"

import "themes"


LocalStorageEntries.clear()

i18n.load({
  'it': messagesIt,
  'en': messagesEn,
})

i18n.activate('it')

const rootNode: HTMLElement = document.getElementById('root')!
const root: ReactDOMClient.Root = ReactDOMClient.createRoot(rootNode)
root.render(
    <I18nProvider i18n={i18n}>
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterLuxon} locale="it">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                    <App />
                </Worker>
            </LocalizationProvider>
        </BrowserRouter>
    </I18nProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
