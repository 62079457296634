import React from "react"

import {
	Location,
	NavigateFunction,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom"

export type UseRouterParams = {
	navigate: NavigateFunction,
	params: any,
	location: Location,
}
const withUseRouter = (Component: React.ComponentType<any>) => {
	return (props: any) => {
		const navigate = useNavigate()
		const params = useParams()
		const location: Location = useLocation()
		return (<Component {...props} navigate={navigate} params={params} location={location} />)
	}
}

export default withUseRouter
