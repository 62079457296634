import React from "react"

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material"

import { Trans } from "@lingui/macro"

interface ChangeSignatureModeModalProps
{
    open: boolean
    confirm: () => void
    reject: () => void
}
interface ChangeSignatureModeModalState
{
}
export class ChangeSignatureModeModal extends React.Component<ChangeSignatureModeModalProps, ChangeSignatureModeModalState>
{
    render()
    {
        return (
            <Dialog
                open={this.props.open}
                onClose={() => false}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Trans
                        id="sign.modals.change-signature-mode.title"
                        comment="Change signature mode modal title">
                        Conferma
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans
                            id="sign.modals.change-signature-mode.message"
                            comment="Asks user to confirm the change signature mode operation">
                            Sei sicuro di voler cambiare modalità di firma?
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={this.props.reject}>
                        <Trans
                            id="sign.modals.change-signature-mode.cancel"
                            comment="Don't want to change signature mode">
                            No, annulla
                        </Trans>
                    </Button>
                    <Button variant="contained"
                            color="primary"
                            onClick={this.props.confirm}>
                        <Trans
                            id="sign.modals.change-signature-mode.confirm"
                            comment="Confirm to want to change signature mode">
                            Sì, conferma
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
