import React from "react"

import {
	Box,
	CssBaseline,
	ThemeOptions,
} from "@mui/material"
import {
	createTheme,
	ThemeProvider,
} from "@mui/material/styles"
import { itIT as coreItIT } from "@mui/material/locale"
import { itIT } from "@mui/x-data-grid"

import itLocale from "date-fns/locale/it"
import { SnackbarProvider } from "notistack"

import { SidebarFaq } from "components/sidebar-faq"

import {
	Header,
	Main,
} from "structure"

import themes, {
	DefaultTheme,
	MuiWrapperContext,
	MuiWrapperUtils,
} from "themes"

interface AppState
{
	drawerOpen: boolean
	currentTheme: string
}
export default class App extends React.Component<{}, AppState>
{
	private static readonly DRAWER_WIDTH: number = 350

	private changeTheme = (theme: string) => {
		this.setState(() => ({ currentTheme: theme }))
	}

	constructor(props: {})
	{
		super(props)
		this.state = {
			drawerOpen: false,
			currentTheme: "default",
		}
	}

	private openCloseDrawer = (): void => {
		const newValue = !this.state.drawerOpen
		this.setState({ drawerOpen: newValue })
	}

	public render()
	{
		const props = {
			drawerOpen: this.state.drawerOpen,
			drawerWidth: App.DRAWER_WIDTH,
		}

		const muiWrapperUtils: MuiWrapperUtils = {
			changeTheme: this.changeTheme,
		}

		let currentTheme: ThemeOptions = DefaultTheme
		if(this.state.currentTheme in themes) {
			currentTheme = themes[this.state.currentTheme]
		}

		const theme = createTheme(
			currentTheme,
			itLocale,
			itIT,
			coreItIT
		)

		return (
			<MuiWrapperContext.Provider value={muiWrapperUtils}>
				<ThemeProvider theme={theme}>
					<SnackbarProvider
						maxSnack={5}
						anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
						hideIconVariant={false}>
						<Box sx={{ display: "flex", }}>
							<CssBaseline />
							<Header openCloseDrawer={this.openCloseDrawer} {...props} />
							<Main {...props} />
							<SidebarFaq openCloseDrawer={this.openCloseDrawer} {...props} />
						</Box>
					</SnackbarProvider>
				</ThemeProvider>
			</MuiWrapperContext.Provider>
		)
	}
}
