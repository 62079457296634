import React from "react"

import {
	Grid,
	Paper,
	Typography,
} from "@mui/material"

import { Trans } from "@lingui/macro"

import {
	LocalStorageEntries,
	SignatureInfo,
	SignatureMode,
} from "common-models"
import withUseRouter, { UseRouterParams } from "services/router"
import { SignatureModeItem } from "components/signature-mode-selection"

import SignatureExternal from "./signature-external.png"
import SignatureManual from "./signature-manual.png"
import SignatureInternal from "./signature-internal.png"
import { ReportService } from "services/report.service"

class SignatureModeSelection extends React.Component<UseRouterParams, {}>
{
	private token: string = LocalStorageEntries.readToken()!

	private signatureInfo: SignatureInfo = LocalStorageEntries.readSignatureInfo()!

	componentDidMount()
	{
		const modes = this.signatureInfo.config?.modes
		const externalSignatureEnabled = modes?.includes(SignatureMode.DIGITAL_EXTERNAL)
		const manualSignatureEnabled = modes?.includes(SignatureMode.MANUAL)

		if(modes?.length === 1) {
			if(externalSignatureEnabled) {
				ReportService.sendReportEntry(this.token, "Attivazione procedura di firma digitale di terze parti")
				setTimeout(() => this.goToSign(SignatureMode.DIGITAL_EXTERNAL, true))
			} else if(manualSignatureEnabled) {
				ReportService.sendReportEntry(this.token, "Attivazione procedura di firma olografa")
				setTimeout(() => this.goToSign(SignatureMode.MANUAL, true))
			} else {
				ReportService.sendReportEntry(this.token, "Attivazione procedura di firma digitale")
				setTimeout(() => this.goToSign(SignatureMode.INTERNAL, true))
			}
		}
	}

	private goToSign = (signMode: SignatureMode, automatic: boolean = false): void => {
		LocalStorageEntries.saveSignatureMode(signMode)

		if(!automatic) {
			let mode: string
			if(signMode === SignatureMode.DIGITAL_EXTERNAL) {
				mode = "digitale di terze parti"
			} else if(signMode === SignatureMode.INTERNAL) {
				mode = "olografa"
			} else {
				mode = "digitale"
			}
			ReportService.sendReportEntry(this.token, `Selezionata procedura di firma ${mode}`)
		}

		let {navigate} = this.props
		navigate("/sign")
	}

	render()
	{
		const modes = this.signatureInfo.config?.modes
		const externalSignatureEnabled = modes?.includes(SignatureMode.DIGITAL_EXTERNAL)
		const manualSignatureEnabled = modes?.includes(SignatureMode.MANUAL)
		const internalSignatureEnabled = modes?.includes(SignatureMode.INTERNAL)

		return (
			<Grid container component={Paper} padding={5} columnSpacing={3} display="flex" justifyContent="center">
				<Grid item xs={12}>
					<Typography variant="h4">
						<Trans id="signature-mode-selection.title" comment="question to choose which signature mode to use">
							Come vuoi firmare?
						</Trans>
					</Typography>
				</Grid>

				{externalSignatureEnabled &&
					<SignatureModeItem
						image={SignatureExternal}
						mode={SignatureMode.DIGITAL_EXTERNAL}
						goToSign={this.goToSign}>
						<Trans id="sign.certifiedMode" comment="use of certified mode">
							Dispongo un certificato di firma qualificata<br />(Intesi Group, InfoCert, Namirial, Aruba, etc)
						</Trans>
					</SignatureModeItem>
				}
				{manualSignatureEnabled &&
					<SignatureModeItem
						image={SignatureManual}
						mode={SignatureMode.MANUAL}
						goToSign={this.goToSign}>
						<Typography variant="body1" sx={{mt: 2}}>
							<Trans id="sign.manualMode" comment="use of manual mode">Voglio firmare manualmente<br />(firma olografa)</Trans>
						</Typography>
					</SignatureModeItem>
				}
				{internalSignatureEnabled &&
					<SignatureModeItem
						image={SignatureInternal}
						mode={SignatureMode.INTERNAL}
						goToSign={this.goToSign}>
						<Typography variant="body1" sx={{mt: 2}}>
							<Trans id="sign.internalMode" comment="use of internal sign mode">Continua con la nostra<br />firma elettronica</Trans>
						</Typography>
					</SignatureModeItem>
				}
			</Grid>
		)
	}
}

export default withUseRouter(SignatureModeSelection)
