import React from "react"

import {
	Button,
	Grid,
} from "@mui/material"
import { Download } from "@mui/icons-material"

import {
	t,
	Trans,
} from "@lingui/macro"

import { AuthenticatedDownload } from "@sinossi/mates-react-library"

import { LocalStorageEntries } from "common-models"

import {
	BaseStep,
	BaseStepProps,
	BaseStepState,
} from "./base-step"
import { ReportService } from "services/report.service"

interface DownloadDocumentStepState extends BaseStepState
{
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
}
export class DownloadDocumentStep extends BaseStep<BaseStepProps, DownloadDocumentStepState>
{
	constructor(props: BaseStepProps)
	{
		super(props)
		this.state = {
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}
	}

	public getStepTitle(): string
	{
		return t({
			id: "sign.download-document",
			comment: "step label download document",
			message: "Scarica il documento",
		})
	}

	private onFileDownloaded = (): void => {
		ReportService.sendReportEntry(this.token, "File da firmare scaricato")
		this.props.stepCompleted()
	}

	private downloadError = (error:any, response: Response): void => {
		ReportService.sendReportEntry(this.token, "Download del file da firmare fallito")

		this.setState(() => ({
			errorDialog: {
				open: true,
				title: "Errore",
				message: "Impossibile scaricare il file. Se il problema persiste contatta il supporto tecnico.",
			},
		}))
	}

	public getStepContent(): React.ReactNode
	{
		return (
			<Grid container>
				{/* // FIXME: sostituire e tradurre */}
				<Grid item xs={12} md={12}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare tempus
					sem id dignissim. Integer sem nisi, fermentum malesuada justo convallis,
					feugiat faucibus orci. Vivamus lectus metus, faucibus in justo laoreet,
					fermentum euismod lorem. Sed ultricies ipsum sed bibendum rutrum. Phasellus
					a ipsum at erat tempor facilisis id non arcu. Nulla pharetra ultrices
					dictum. Pellentesque a sagittis ligula, et malesuada felis. Sed ut
					vestibulum neque. Praesent quis ipsum non lacus pellentesque faucibus.
					Pellentesque accumsan, mi in porta fringilla, elit massa pharetra mauris,
					scelerisque venenatis lectus neque viverra elit. Morbi hendrerit, mauris
					varius viverra posuere, felis lectus auctor odio, eget gravida sapien elit
					ac sem. Fusce sit amet nulla condimentum odio convallis fa
				</Grid>

				<Grid item xs={12} textAlign="center">
					<AuthenticatedDownload
						url={`/api/process/get-document/${LocalStorageEntries.readToken()}?mode=DOWNLOAD`}
						onSuccess={this.onFileDownloaded}
						onError={this.downloadError}>
						<Button
							variant="contained"
							startIcon={<Download />}>
							<Trans
								id="otp-sign-download"
								comment="document download">
								SCARICA DOCUMENTO
							</Trans>
						</Button>
					</AuthenticatedDownload>
				</Grid>
			</Grid>
		)
	}
}
