import React from "react"

import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material"
import {
	ArrowBack,
	Check,
	Close,
	Key,
	Refresh,
	ReportProblem,
} from "@mui/icons-material"

import {
	t,
	Trans,
} from "@lingui/macro"

import {
	ErrorDialog,
	ErrorResponse,
	NetworkParams,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	LocalStorageEntries,
	SignatureInfo,
} from "common-models"
import withUseRouter, { UseRouterParams } from "services/router"
import {
	ErrorService,
	ServerErrorCodes,
} from "services/error.service"

import {
	ReportProblemModal,
	ChangeSignatureModeModal
} from "../modals"
import {
	AbortProcessMode,
	BaseStep,
	BaseStepProps,
	BaseStepState,
} from "./base-step"
import { ReportService } from "services/report.service"

interface OtpstepProps extends BaseStepProps, UseRouterParams
{
}
interface OtpStepState extends BaseStepState
{
	otpMessage: boolean
	disableSubmit: boolean
	otp: string
	resendIcon: JSX.Element
	otpSentMessage: JSX.Element
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
	openReport: boolean
	openChangeSignature: boolean
	showChangeSignature: boolean
}
class OtpStep extends BaseStep<OtpstepProps, OtpStepState>
{
	private signatureInfo: SignatureInfo = LocalStorageEntries.readSignatureInfo()!

	constructor(props: OtpstepProps)
	{
		super(props)
		this.state = {
			otpMessage: false,
			disableSubmit: true,
			otp: "",
			resendIcon: this.setRefresh(),
			otpSentMessage: <></>,
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
			openReport: false,
			openChangeSignature: false,
			showChangeSignature: this.showChangeSignature()
		}
	}

	// implemented from abstract parent
	public getStepTitle = (): string => {
		return t({
			id: "sign.document-sign-step",
			comment: "step label document signing",
			message: "Firma il documento",
		})
	}

	// implemented from abstract parent
	public getStepContent = (): React.ReactNode => {
		return (
			<>
				<ErrorDialog
					ok={this.onErrorDialogClose}
					okText="OK"
					{...this.state.errorDialog} />
				<ReportProblemModal
					open={this.state.openReport}
					confirm={this.reportProblem}
					reject={this.handleCloseReport} />
				<ChangeSignatureModeModal
					open={this.state.openChangeSignature}
					confirm={this.changeSignature}
					reject={this.closeChangeSignature} />

				{this.state.showChangeSignature && <Button
					type="button"
					variant="outlined"
					size="small"
					color="primary"
					startIcon={<ArrowBack fontSize="small" />}
					sx={{mb: 2}}
					onClick={this.handleOpenChangeSignature}>
					<Trans
						id="sign.otp-step.change-signature"
						comment="Button to go back to the previous page to change signature mode">
						Cambia modalità di firma
					</Trans>
				</Button>}

				<Stack spacing={1} mt={2}>
					<Typography variant="h6" color="primary" textAlign="center">
						<Trans id="sign.otp-form.message1" comment="Message to invite user to digit OTP received via SMS">
							Inserisci il Codice One Time Password (OTP) che hai ricevuto via SMS sul cellulare indicato e premi Avanti
						</Trans>
					</Typography>
					<Typography variant="body2" display="flex" alignItems="center" justifyContent="center">
						<Trans id="sign.otp-form.message2" comment="Message to invite user to digit OTP received via SMS">
							Se non hai ricevuto il codice puoi richiederne uno nuovo premendo
						</Trans>
						<Refresh />
					</Typography>

					<Box sx={{display: "flex", justifyContent: "center"}}>
						<TextField
							fullWidth
							autoFocus
							label="Codice One Time Password (OTP) ricevuto via SMS"
							variant="standard"
							onClick={this.hideMessage}
							onChange={this.handleInputChange}
							value={this.state.otp}
							sx={{width: "25%", mt: 3}}
							inputProps={{maxLength: 12}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Key />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										{this.state.resendIcon}
									</InputAdornment>
								),
							}}
							error={this.state.otpMessage}
							helperText={
								this.state.otpMessage ?
									<Trans id="home-otp-error">Codice One Time Password (OTP) sbagliato</Trans> :
									"Solo numeri, massimo 12 caratteri"
							}
						/>
					</Box>

					{this.state.otpSentMessage}
				</Stack>
				<Grid container spacing={2} mt={3}>
					<Grid item xs={12} md={6}>
						{this.signatureInfo.config?.errorReportsEnabled &&
							<Button
								variant="text"
								sx={{fontSize: "0.7em"}}
								startIcon={<ReportProblem fontSize="small" />}
								onClick={this.handleOpenReport}>
								Segnala un problema
							</Button>
						}
					</Grid>
					<Grid item xs={12} md={6} textAlign="right">
						<Button
							type="button"
							variant="contained"
							color="warning"
							onClick={() => this.abortProcess(AbortProcessMode.REQUIRE_CONFIRM)}
							startIcon={<Close />}>
							<Trans id="otp-reject" comment="reject">Annulla</Trans>
						</Button>
						<Button
							disabled={this.state.disableSubmit}
							type="button"
							variant="contained"
							color="primary"
							onClick={this.signDocument}
							startIcon={<Check />}
							sx={{ml: 2}}>
							<Trans id="otp-sign-next" comment="next button">Avanti</Trans>
						</Button>
					</Grid>
				</Grid>
			</>
		)
	}

	private setCircularProgress = (): JSX.Element => {
		return <CircularProgress size="18px" />
	}

	private setRefresh = (): JSX.Element => {
		return (
			<IconButton onClick={this.resendOtp} edge="end">
				<Refresh />
			</IconButton>
		)
	}

	private showChangeSignature = (): boolean => {
		const signatureInfo: SignatureInfo = LocalStorageEntries.readSignatureInfo()!
		const modes = signatureInfo.config?.modes

		return modes?.length !== 1
	}

	private handleOpenChangeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Richiesto cambio modalità di firma")
		this.setState(() => ({openChangeSignature: true}))
	}

	private closeChangeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Annullato cambio modalità di firma")
		this.setState(() => ({openChangeSignature: false}))
	}

	private changeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Confermato cambio modalità di firma")
		this.setState(() => ({openChangeSignature: false}))
		this.props.showLoading()
		RestService
			.delete<void>(`/api/process/${this.token}/signature`)
			.then(this.onChangeSignatureSuccess)
			.catch(this.onChangeSignatureError)
	}

	private onChangeSignatureSuccess = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onChangeSignatureError(response)
			return
		}

		LocalStorageEntries.clearSignatureMode()

		let {navigate} = this.props
		navigate("/signature-selection")
	}

	private onChangeSignatureError = (response: RestResponse<void, ErrorResponse>): void => {
		this.props.hideLoading()
		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	private hideMessage = (): void => {
		this.setState(() => ({ otpMessage: false, }))
	}

	private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		let otp: string = event.target.value
		otp = otp.replace(/\D/g, "")

		let length = otp.length
		this.setState(() => ({
			disableSubmit: length < 5 || length > 12,
			otp: otp,
		}))
	}

	private resendOtp = (event: React.MouseEvent): void => {
		ReportService.sendReportEntry(this.token, "Richiesto reinvio OTP di firma")
		event.preventDefault()
		event.stopPropagation()
		this.setState(() => ({resendIcon: this.setCircularProgress()}))
		const url = `/api/process/${this.token}/sms`
		RestService
			.put<void>(url, null)
			.then(this.showOtpSentMessage)
			.catch(this.onResendError)
	}

	private showOtpSentMessage = (response: RestResponse<any, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onResendError(response)
			return
		}

		ReportService.sendReportEntry(this.token, "OTP di firma reinviato con successo")

		this.setState(() => ({
			resendIcon: this.setRefresh(),
			otpSentMessage: (
				<>
					<Typography variant="body2" mt={1} textAlign="center">
						<Trans
							id="home-otp-sent">
							Abbiamo inviato un SMS al tuo cellulare contenente il Codice One Time Password (OTP)
						</Trans>
					</Typography>
				</>
			),
		}))
	}

	private onResendError = (response: RestResponse<void, ErrorResponse>): void => {
		ReportService.sendReportEntry(this.token, "Reinvio OTP di firma fallito")

		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		if(errorCodeFromServer === ServerErrorCodes.CANNOT_SEND_OTP) {
			this.setState(() => ({
				resendIcon: this.setRefresh(),
				otpSentMessage: <></>,
				errorDialog: {
					open: true,
					title: t({
						id: "generic.send-otp-error.title",
						message: "Errore",
						comment: "Title for send OTP error modal",
					}),
					message: t({
						id: "generic.send-otp-error.message",
						message: "È avvenuto un errore imprevisto durante l'invio del Codice One Time Password (OTP). Si prega di riprovare.",
						comment: "Message for send OTP error modal",
					}),
				}
			}))
			return
		}

		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	private signDocument = (): void => {
		this.props.showLoading()

		let request = {
			"otp": this.state.otp,
			"mode": LocalStorageEntries.readSignatureMode()!,
		}
		let body: FormData = new FormData()
		body.append(
			"request",
			new Blob([ JSON.stringify(request) ], { type: "application/json" }),
			""
		)

		ReportService.sendReportEntry(this.token, "Richiesta firma del documento")

		const params = new NetworkParams()
		params.contentType = undefined
		RestService
			.genericPut<void, ErrorResponse>(`/api/process/${this.token}/signature`, body, params)
			.then(this.onSignSuccess)
			.catch(this.onSignError)
	}

	private onSignSuccess = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onSignError(response)
			return
		}

		ReportService.sendReportEntry(this.token, "Documento firmato con successo")

		this.props.hideLoading()
		const { navigate } = this.props
		navigate("/complete")
	}

	private onSignError = (response: RestResponse<void, ErrorResponse>): void => {
		this.props.hideLoading()
		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		if(errorCodeFromServer === ServerErrorCodes.GENERATION_ERROR ||
			errorCodeFromServer === ServerErrorCodes.SIGN_ERROR) {
			ReportService.sendReportEntry(this.token, "Firma documento fallita")
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: t({
						id: "sign.signature-failed-error.title",
						message: "Firma fallita",
						comment: "Title for signature failed error modal",
					}),
					message: t({
						id: "sign.signature-failed-error.message",
						message: "È avvenuto un errore imprevisto durante la firma. Ti preghiamo di riprovare.",
						comment: "Message for signature failed error modal",
					}),
				}
			}))
			return
		} else if(errorCodeFromServer === ServerErrorCodes.WRONG_OTP) {
			ReportService.sendReportEntry(this.token, "Firma documento fallita: OTP errato")
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: t({
						id: "generic.wrong-otp-error.title",
						message: "Codice sbagliato",
						comment: "Title for wrong OTP error modal",
					}),
					message: t({
						id: "generic.wrong-otp-error.message",
						message: "Il Codice One Time Password (OTP) che hai inserito è sbagliato. Verifica di aver digitato il valore corretto o richiedi un nuovo Codice tramite l'apposito pulsante.",
						comment: "Message for wrong OTP error modal",
					}),
				}
			}))
			return
		}

		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	private handleOpenReport = (): void => {
		this.setState(() => ({openReport: true}))
	}

	private handleCloseReport = (): void => {
		this.setState(() => ({openReport: false}))
	}

	private reportProblem = (problemDescription: string): void => {
		this.setState(() => ({openReport: false}))
		this.props.showLoading()

		ReportService.sendReportEntry(
			this.token,
			problemDescription,
			this.onReportSent,
			this.onReportProblemError,
		)
	}

	private onReportSent = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onReportProblemError()
			return
		}

		this.props.hideLoading()
		this.setState(() => ({
			errorDialog: {
				open: true,
				title: "Grazie per la segnalazione", // FIXME: tradurre
				message: "Abbiamo ricevuto la tua segnalazione: un operatore la verificherà quanto prima. Grazie.", // FIXME: tradurre
			},
		}))
	}

	private onReportProblemError = (): void => {
		this.props.hideLoading()
		this.setState(() => ({
			errorDialog: {
				open: true,
				title: t({
					id: "sign.report-problem-failed.title",
					message: "Errore",
					comment: "Title for report problem failed modal",
				}),
				message: t({
					id: "sign.report-problem-failed.message",
					message: "Non è stato possibile inviare la segnalazione, si prega di riprovare.",
					comment: "Message for report problem failed modal",
				}),
			},
		}))
	}

	private onErrorDialogClose = (): void => {
		this.props.hideLoading()
		this.setState(() => ({
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}))
	}
}

export default withUseRouter(OtpStep)
