import React from "react"

import {
	Button,
	Paper,
	Stack,
	Typography,
} from "@mui/material"
import { ArrowForward } from "@mui/icons-material"

import { Trans } from "@lingui/macro"

import {
	ErrorResponse,
	Loading,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	CallbackUrls,
	LocalStorageEntries,
} from "common-models"
import { ErrorService } from "services/error.service"
import withUseRouter, { UseRouterParams } from "services/router"

import CheckCircle from "./check-circle.png"

interface CompleteProps extends UseRouterParams
{
}
interface CompleteState
{
	loading: boolean
}
class Complete extends React.Component<CompleteProps, CompleteState>
{
	private readonly token: string = LocalStorageEntries.readToken()!

	constructor(props: CompleteProps)
	{
		super(props)
		this.state = {
			loading: false,
		}
	}

	private completeProcess = (): void => {
		this.setState(() => ({ loading: true, }))
		let url = `/api/process/${this.token}/complete`
		RestService
			.post<any>(url, null)
			.then(this.onCompleteSuccess)
			.catch(this.onCompleteError)
	}

	private onCompleteSuccess = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.setState(() => ({ loading: false, }))
			this.onCompleteError(response)
			return
		}

		const callbackUrls: CallbackUrls = LocalStorageEntries.readCallbackUrls()!
		window.location.href = callbackUrls.successUrl
	}

	private onCompleteError = (response: RestResponse<void, ErrorResponse>): void => {
		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	render()
	{
		return (
			<>
				<Loading show={this.state.loading} />

				<Paper sx={{p: 3}}>
					<Stack spacing={2} mt={2} mb={2} direction="column" justifyContent="center" alignItems="center">
						<img src={CheckCircle} style={{ height: "8em", marginBottom: "16px" }} alt="" />

						<Typography color="primary" variant="h5">
							<Trans
								id="sign.steps.process.document-signed"
								comment="document signed Successfully">
								Documento firmato correttamente
							</Trans>
						</Typography>

						<Typography variant="body1">
							<Trans
								id="sign.steps.process.confirm"
								comment="process complete confirmation">
								Premi su Avanti per concludere il processo
							</Trans>
						</Typography>

						<Button
							color="primary"
							variant="contained"
							onClick={this.completeProcess}
							endIcon={<ArrowForward />}>
							<Trans id="sign.steps.process.next">
								Avanti
							</Trans>
						</Button>
					</Stack>
				</Paper>
			</>
		)
	}
}

export default withUseRouter(Complete)
