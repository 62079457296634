import { createContext } from "react"

interface MuiWrapperUtils
{
	changeTheme: (theme: string) => void
}
const MuiWrapperContext = createContext<MuiWrapperUtils | null>(null)

export type { MuiWrapperUtils }
export { MuiWrapperContext }
