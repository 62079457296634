import React from "react"

import {
	Step,
	StepButton,
	StepContent,
} from "@mui/material"

import { LocalStorageEntries } from "common-models"

export enum AbortProcessMode
{
	REQUIRE_CONFIRM,
	NO_CONFIRM_NEEDED,
}
export interface BaseStepProps
{
	index: number
	showLoading: () => void
	hideLoading: () => void
	abortProcess: (mode: AbortProcessMode) => void
	stepCompleted: () => void
}
export interface BaseStepState
{
}
export abstract class BaseStep
	<P extends BaseStepProps = BaseStepProps, S extends BaseStepState = BaseStepState>
	extends React.Component<P, S>
{
	protected token: string = LocalStorageEntries.readToken()!

	abstract getStepTitle(): React.ReactNode|string

	abstract getStepContent(): React.ReactNode

	protected abortProcess = (mode: AbortProcessMode): void => {
		this.props.abortProcess(mode)
	}

	public render()
	{
		return (
			<Step index={this.props.index}>
				<StepButton>
					{this.getStepTitle()}
				</StepButton>
				<StepContent>
					{this.getStepContent()}
				</StepContent>
			</Step>
		)
	}
}
