import React from "react"

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material"

import { Trans } from "@lingui/macro"

interface ReportProblemModalProps
{
    open: boolean
    confirm: (problemDescription: string) => void
    reject: () => void
}
interface ReportProblemModalState
{
    problemDescription: string
}
export class ReportProblemModal extends React.Component<ReportProblemModalProps, ReportProblemModalState>
{
    constructor(props: ReportProblemModalProps)
    {
        super(props)
        this.state = {
            problemDescription: "",
        }
    }

    private setDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let problem: string = event.target.value
        this.setState(() => ({problemDescription: problem}))
    }

    render()
    {
        return (
            <Dialog
                open={this.props.open}
                onClose={() => false}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Trans
                        id="sign.modals.report-problem.title"
                        comment="Report problem modal title">
                        Descrivi il problema
                    </Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans
                            id="sign.modals.report-problem.message"
                            comment="Report problem modal text">
                            Indica il problema nel campo qui sotto, in modo che i nostri tecnici possano verificare e risolvere nel più breve tempo possibile.
                        </Trans>
                    </DialogContentText>
                    <TextField
                        multiline
                        label={
                        <Trans
                            id="sign.modals.report-problem-description"
                            comment="Label value of the report problem textfield.">
                            Descrizione problema
                        </Trans>
                        }
                        id="ProblemDescription"
                        variant="standard"
                        fullWidth={true}
                        inputProps={{ maxLength: 2000 }} // FIXME: spostare su configurazione
                        onChange={this.setDescription}
                        helperText={
                            <Trans
                                id="sign.modals.report-problem.max-length"
                                comment="Inform che user about the maximum length of the report.">
                                Max 2000 caratteri.
                            </Trans>
                        }
                        sx={{ mt: 1 }} />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={this.props.reject}>
                        <Trans
                            id="generic.cancel"
                            comment="Cancel">
                            Annulla
                        </Trans>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.confirm(this.state.problemDescription)}>
                        <Trans
                            id="sign.modals.report-problem.confirm"
                            comment="Confirm report send">
                            Invia segnalazione
                        </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
