import React from "react"

import {
	Box,
	Fab,
} from "@mui/material"
import { ArrowForward } from "@mui/icons-material"

import { HighlightArea } from "@react-pdf-viewer/highlight"

import {
	ErrorDialog,
} from "@sinossi/mates-react-library"

import {
	LocalStorageEntries,
	SignatureInfo,
} from "common-models"
import withUseRouter, { UseRouterParams } from "services/router"
import PdfViewerHighlight from "components/pdf-viewer-highlight/pdfviewer-highlight"

import "./style.scss"
import { ReportService } from "services/report.service"

export const width: number = 595
export const height: number = 842

export interface PdfViewerState
{
	isScrolled: boolean
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
}
class PdfViewer extends React.Component<UseRouterParams, PdfViewerState>
{
	private readonly token: string = LocalStorageEntries.readToken()!
	private readonly info: SignatureInfo = LocalStorageEntries.readSignatureInfo()!

	private readonly coordinates: HighlightArea[]

	constructor(props: UseRouterParams)
	{
		super(props)

		this.state = {
			isScrolled: false,
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}

		this.coordinates = this.toLocalCoordinates()
	}

	private toLocalCoordinates = (): HighlightArea[] => {
		let coordinates: SignatureInfo | null = LocalStorageEntries.readSignatureInfo()

		if(coordinates === null || coordinates.list === null || coordinates.list === undefined) {
			return [] //TODO error?
		}

		return coordinates.list!
			.filter(value => value.box !== null && value.box !== undefined)
			.map(value => {
				return {
					pageIndex: value.page!,
					width: value.box!.width! * 100 / width,
					height: value.box!.height! * 100 / height,
					left: value.box!.x! * 100 / width,
					top: value.box!.y! * 100 / height,
				}
			})
	}

	private handleScroll = (): void => {
		this.setState(() => ({
			isScrolled: true,
		}))
	}

	private canContinue = (): boolean => {
		const continueRequireScroll: boolean = this.info.config?.continueRequireScroll === true
		return !continueRequireScroll || this.state.isScrolled
	}

	private proceedToNextPage = (): void => {
		if(!this.canContinue()) {
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: "Attenzione", // FIXME: tradurre
					message: "Scorri e leggi l'intero documento per attivare il pulsante e proseguire con la procedura di firma", // FIXME: tradurre
				}
			}));
			return
		}

		ReportService.sendReportEntry(this.token, "Documento visionato")

		let {navigate} = this.props
		navigate("/signature-selection")
	}

	private onErrorDialogClose = (): void => {
		this.setState(() => ({
			errorDialog: {
				open: false,
				title: "",
				message: "",
			}
		}));
	}

	render()
	{
		return (
			<>
				<ErrorDialog
					ok={this.onErrorDialogClose}
					okText="OK"
					{...this.state.errorDialog} />

				<Box component="div" className="todo-container">
					<PdfViewerHighlight
						areas={this.coordinates}
						fileUrl={`/api/process/get-document/${this.token}?mode=PREVIEW`}
						onScroll={this.handleScroll} />
				</Box>
				{/* // FIXME: tradurre */}
				<Fab
					variant="extended"
					color={this.canContinue() ? "secondary" : "neutral"}
					onClick={this.proceedToNextPage}
					sx={{
						scale: "1.4",
						position: "absolute",
						right: "4%",
						bottom: "5%",
					}}>
					Continua
					<ArrowForward sx={{ ml: 1 }} />
				</Fab>
			</>
		)
	}
}

export default withUseRouter(PdfViewer)
