import React from "react"

import {
	Button,
	Divider,
	Grid,
	Typography,
} from "@mui/material"
import {
	Check,
	Close,
	Refresh,
} from "@mui/icons-material"

import {
	Trans,
} from "@lingui/macro"

import {
	ClauseStatus,
	SignatureData,
} from "common-models"
import { ConfirmClauseRejectModal } from "components/sign/modals"

interface ClauseProps
{
	clause: SignatureData
	updateClause: (clauseId: string, status: ClauseStatus) => void
	abortProcess: () => void
}
interface ClauseState
{
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
	showConfirmClauseRejectModal: boolean
}
export class Clause extends React.Component<ClauseProps, ClauseState>
{
	constructor(props: ClauseProps)
	{
		super(props)
		this.state = {
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
			showConfirmClauseRejectModal: false,
		}
	}

	private updateClause = (status: ClauseStatus): void => {
		if(this.props.clause.mandatory && status === ClauseStatus.REJECTED) {
			this.showMandatoryClauseRejectedError()
			return
		}

		this.props.updateClause(this.props.clause.id!, status)
	}

	private showMandatoryClauseRejectedError = () => {
		this.setState(() => ({
			showConfirmClauseRejectModal: true,
		}))
	}

	private abortProcess = (): void => {
		this.hideMandatoryClauseRejectedError()
		this.props.abortProcess()
	}

	private hideMandatoryClauseRejectedError = (): void => {
		this.setState(() => ({
			showConfirmClauseRejectModal: false,
		}))
	}

	render()
	{
		const clause: SignatureData = this.props.clause

		return (
			<>
				<ConfirmClauseRejectModal
					open={this.state.showConfirmClauseRejectModal}
					confirm={this.abortProcess}
					reject={this.hideMandatoryClauseRejectedError} />

				<Grid container spacing={2}>
					<Grid item xs={12} md={9}>
						{clause.mandatory && <><Typography variant="h6" color="error" component="span">*</Typography><span>&nbsp;</span></>}
						<Typography variant="h6" component="span">{clause.name}</Typography>
						<Typography variant="body2" component="div">{clause.clause}</Typography>
					</Grid>
					<Grid item xs={12} md={3} display="flex" justifyContent="right" alignItems="center">
						{clause.status === ClauseStatus.PENDING &&
						<>
							<Button
								variant="contained"
								color="warning"
								startIcon={<Close />}
								onClick={() => this.updateClause(ClauseStatus.REJECTED)}
								sx={{ml: 2}}>
								<Trans
									id="clause.reject"
									comment="Reject clause">
									Rifiuta
								</Trans>
							</Button>
							<Button
								variant="contained"
								color="primary"
								startIcon={<Check />}
								onClick={() => this.updateClause(ClauseStatus.ACCEPTED)}
								sx={{ml: 2}}>
								<Trans
									id="clause.accept"
									comment="Accept clause">
									Accetta
								</Trans>
							</Button>
						</>
						}

						{clause.status !== ClauseStatus.PENDING &&
						<>
							{clause.status === ClauseStatus.ACCEPTED &&
								// FIXME: tradurre
								<Button
									variant="text"
									startIcon={<Check />}
									color="primary"
									sx={{mr: 1}}>
									<Trans id="sign.clause.accepted" comment="Sign clause has been accepted">
										Accettata
									</Trans>
								</Button>
							}
							{clause.status === ClauseStatus.REJECTED &&
								// FIXME: tradurre
								<Button
									variant="text"
									startIcon={<Close />}
									color="error"
									sx={{mr: 1}}>
									<Trans id="sign.clause.rejected" comment="Sign clause has been rejected">
										Rifiutata
									</Trans>
								</Button>
							}

							<Button
								variant="contained"
								color="neutral"
								startIcon={<Refresh />}
								onClick={() => this.updateClause(ClauseStatus.PENDING)}>
								<Trans
									id="clause.reset-clause"
									comment="Reset clause">
									Ho cambiato idea
								</Trans>
							</Button>
						</>
						}
					</Grid>
					<Grid item xs={12} mt={2} mb={2}>
						<Divider variant="middle" />
					</Grid>
				</Grid>
			</>
		)
	}
}
