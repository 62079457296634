import { ThemeOptions } from "@mui/material"

const theme: ThemeOptions = {
	palette: {
		mode: 'light',
		background: {
			default: "#F7F7FF",
		},
		primary: {
			main: '#00AFF0',
			contrastText: "#FFFFFF",
		},
		secondary: {
			main: '#4F9D69',
		},
		neutral: {
			main: '#FBFEF9',
			contrastText: "#03254E",
		},
		neutralSecondary: {
			main: '#FBFEF9',
			contrastText: "#4F9D69",
		},
		warning: {
			main: "#ff7f00",
			contrastText: "#FFFFFF",
		}
	}
} as ThemeOptions

export default theme
