import React from "react"

import {
	Button,
	Grid,
	Typography,
} from "@mui/material"
import {
	ArrowForward,
} from "@mui/icons-material"

import { Trans } from "@lingui/macro"

import { ChildrenComponentProp } from "services/utils"
import { SignatureMode } from "./models"

interface SignatureModeItemProps extends ChildrenComponentProp
{
	image: any
	mode: SignatureMode
	goToSign: (mode: SignatureMode) => void
}
export class SignatureModeItem extends React.Component<SignatureModeItemProps, {}>
{
	render()
	{
		return (
			<Grid item xs={12} md={6} lg={4} textAlign="center" sx={{ mt: 5 }}>
				<img src={this.props.image} style={{ height: "10em" }} alt="" />

				<Typography variant="body1" sx={{mt: 2}}>
					{this.props.children}
				</Typography>

				<Button variant="contained"
					color="primary"
					sx={{mt: 2}}
					onClick={() => this.props.goToSign(this.props.mode)}
					endIcon={<ArrowForward />}>
					<Trans id="generic.proceed" comment="proceed to next step">Prosegui</Trans>
				</Button>
			</Grid>
		)
	}
}
