import React from "react"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material"

import { Trans } from "@lingui/macro"

interface ConfirmCertificateEmissionModalProps
{
	open: boolean
	confirm: () => void
	reject: () => void
}
export class ConfirmCertificateEmissionModal extends React.Component<ConfirmCertificateEmissionModalProps, {}>
{
	render()
	{
		return (
			<Dialog
				open={this.props.open}
				onClose={() => false}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle>
					<Trans
						id="sign.modals.confirm-certificate-emission.title"
						comment="Confirm certificate emission modal title">
						Firma documento
					</Trans>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Trans
							id="sign.modals.confirm-certificate-emission.message"
							comment="Confirm certificate emission modal message">
							Confermi di sottoscrivere le clausole indicate?<br /><br />
							<strong>NOTA BENE!</strong> Annullando il processo, questo verrà interrotto in via definitiva e 
							dovrai richiedere un nuovo invito alla firma.
						</Trans>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="warning" onClick={this.props.reject}>
						<Trans
							id="sign.modals.confirm-certificate-emission.no"
							comment="Certificate emission alert reject">
							Annulla il processo
						</Trans>
					</Button>
					<Button variant="contained" color="primary" onClick={this.props.confirm}>
						<Trans
							id="sign.modals.confirm-certificate-emission.yes"
							comment="Certificate emission alert confirmation">
							Procedi
						</Trans>
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}
