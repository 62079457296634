import React from "react"

import {
	t,
	Trans,
} from "@lingui/macro"

import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material"
import {
	Key,
	Refresh,
} from "@mui/icons-material"

import {
	ErrorResponse,
	Loading,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	LocalStorageEntries,
} from "common-models"
import withUseRouter, { UseRouterParams } from "services/router"
import {
	ErrorService,
	ServerErrorCodes,
} from "services/error.service"
import { ReportService } from "services/report.service"

export interface OtpFormProps extends UseRouterParams
{
	onOtpValidated: () => void
}
export interface OtpFormState
{
	loading: boolean
	otpMessage: boolean
	disableSubmit: boolean
	otp: string
	resendIcon: JSX.Element
	otpSentMessage: JSX.Element
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
}
class OtpForm extends React.Component<OtpFormProps, OtpFormState>
{
	private token: string = LocalStorageEntries.readToken()!

	public constructor(props: OtpFormProps)
	{
		super(props)
		this.state = {
			loading: false,
			disableSubmit: true,
			otpMessage: false,
			otp: "",
			resendIcon: this.setRefresh(),
			otpSentMessage: <></>,
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}
	}

	private resendOtp = (): void => {
		ReportService.sendReportEntry(this.token, "Reinvio OTP di accesso...")

		this.setState(() => ({resendIcon: this.setCircularProgress()}))
		const url = `/api/process/${this.token}/sms`
		RestService
			.put<void>(url, null)
			.then(this.showOtpSentMessage)
			.catch(this.onError)
	}

	private showOtpSentMessage = (response: RestResponse<any, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onError(response)
			return
		}

		ReportService.sendReportEntry(this.token, "OTP di accesso reinviato")
		this.setState(() => ({
			otpMessage: true,
			resendIcon: this.setRefresh(),
			otpSentMessage: (
				<>
					<Typography variant="body2" mt={1} textAlign="center">
						<Trans
							id="home.otp-sent-message">
							Abbiamo inviato un SMS al tuo cellulare contenente il Codice One Time Password (OTP)
						</Trans>
					</Typography>
				</>
			),
		}))
	}

	private onError = (response: RestResponse<void, ErrorResponse>): void => {
		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		if(errorCodeFromServer === ServerErrorCodes.WRONG_OTP) {
			ReportService.sendReportEntry(this.token, "Validazione OTP di accesso fallita")
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: t({
						id: "generic.wrong-otp-error.title",
						message: "Codice sbagliato",
						comment: "Title for wrong OTP error modal",
					}),
					message: t({
						id: "generic.wrong-otp-error.message",
						message: "Il Codice One Time Password (OTP) che hai inserito è sbagliato. Verifica di aver digitato il valore corretto o richiedi un nuovo Codice tramite l'apposito pulsante.",
						comment: "Message for wrong OTP error modal",
					}),
				}
			}))
			return
		} else if(errorCodeFromServer === ServerErrorCodes.CANNOT_SEND_OTP) {
			ReportService.sendReportEntry(this.token, "Richiesta di reinvio OTP di accesso fallita")
			this.setState(() => ({
				resendIcon: this.setRefresh(),
				otpSentMessage: <></>,
				errorDialog: {
					open: true,
					title: t({
						id: "generic.send-otp-error.title",
						message: "Errore",
						comment: "Title for send OTP error modal",
					}),
					message: t({
						id: "generic.send-otp-error.message",
						message: "È avvenuto un errore imprevisto durante l'invio del Codice One Time Password (OTP). Si prega di riprovare.",
						comment: "Message for send OTP error modal",
					}),
				}
			}))
			return
		}

		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	private hideMessage = (): void => {
		this.setState(() => ({otpMessage: false}))
	}

	private submitForm = (event: React.FormEvent): void => {
		event.preventDefault()
		event.stopPropagation()

		this.setState(() => ({loading: true, }))
		ReportService.sendReportEntry(this.token, "Validazione OTP di accesso...")

		let url = `/api/process/${this.token}/otp`
		RestService
			.post<void>(url, {otp: this.state.otp})
			.then(this.validateOtp)
			.catch(this.onError)
	}

	private handleInputChange = (event: any): void => {
		let otp: string = event.target.value
		otp = otp.replace(/\D/g, "")

		let length = otp.length
		this.setState(() => ({
			disableSubmit: length < 5 || length > 12,
			otp: otp,
		}))
	}

	private validateOtp = (response: RestResponse<any, ErrorResponse>): void => {
		if(response.hasError()) {
			this.setState(() => ({
				otpMessage: true,
				loading: false,
			}))
			return
		}

		ReportService.sendReportEntry(this.token, "OTP di accesso validato con successo")

		console.log("otp successful")
		this.setState(() => ({loading: false, }))
		this.props.onOtpValidated()
	}

	private setCircularProgress = (): JSX.Element => {
		return <CircularProgress size="18px" />
	}

	private setRefresh = (): JSX.Element => {
		return (
			<IconButton onClick={this.resendOtp} edge="end">
				<Refresh />
			</IconButton>
		)
	}

	render()
	{
		return (
			<>
				<Loading show={this.state.loading} />

				<Grid container spacing={2}>
					<Grid item sm={12} lg={12}>
						<Trans id="home.otp-form.title" comment="Message to invite user to digit OTP received via SMS">
							Per poter accedere devi inserire il Codice One Time Password (OTP) che hai ricevuto via SMS sul tuo cellulare:
						</Trans>
					</Grid>

					<Grid item xs={0} md={3} />
					<Grid item xs={12} md={6}>
						<form onSubmit={this.submitForm}>
							<Box component="div">
								<TextField
									fullWidth
									autoFocus
									label={t({
										id: "home.otp-form.field-label",
										message: "Codice One Time Password (OTP) ricevuto via SMS",
										comment: "OTP text field label",
									})}
									variant="standard"
									onClick={this.hideMessage}
									onChange={this.handleInputChange}
									inputProps={{maxLength: 12}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Key />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												{this.state.resendIcon}
											</InputAdornment>
										),
									}}
									error={this.state.otpMessage}
									helperText={
										this.state.otpMessage ?
											<Trans id="home-otp-error">Codice One Time Password (OTP) sbagliato</Trans> :
											"Solo numeri, massimo 12 caratteri"
									}
								/>
							</Box>
							{this.state.otpSentMessage}
						</form>
					</Grid>
					<Grid item xs={0} md={3} />

					<Grid item xs={0} md={5} />
					<Grid item xs={12} md={2}>
						<Button
							disabled={this.state.disableSubmit}
							onClick={this.submitForm}
							type="submit"
							variant="contained"
							fullWidth>
							<Trans id="otp-access">Accedi</Trans>
						</Button>
					</Grid>
					<Grid item xs={0} md={5} />
				</Grid>
			</>
		)
	}
}

export default withUseRouter(OtpForm)
