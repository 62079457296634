import React from "react"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material"

import { Trans } from "@lingui/macro"

interface ConfirmClauseRejectModalProps
{
	open: boolean
	confirm: () => void
	reject: () => void
}
export class ConfirmClauseRejectModal extends React.Component<ConfirmClauseRejectModalProps, {}>
{
	render()
	{
		return (
			<Dialog
				open={this.props.open}
				onClose={() => false}
				fullWidth={true}
				maxWidth="sm"
			>
				<DialogTitle>
					<Trans
						id="sign.modals.confirm-clause-reject.title"
						comment="Certificate clause reject modal title">
						Attenzione
					</Trans>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Trans
							id="sign.modals.confirm-clause-reject.message"
							comment="Certificate clause reject modal message">
							Hai rifiutato una clausola obbligatoria, se non la approvi non potrai proseguire e il documento non sarà firmato. Sei sicuro di voler annullare il processo?
						</Trans>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="error" onClick={this.props.confirm}>
						<Trans
							id="sign.modals.confirm-clause-reject.yes"
							comment="Certificate emission alert confirmation">
							Rifiuta e chiudi il processo
						</Trans>
					</Button>
					<Button variant="contained" color="secondary" onClick={this.props.reject}>
						<Trans
							id="sign.modals.confirm-clause-reject.no"
							comment="Certificate emission alert reject">
							Ci ho ripensato
						</Trans>
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}
