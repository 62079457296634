import React from "react"

import {
	Box,
	Button,
	Typography,
} from "@mui/material"
import {
	ReportProblem,
	Warning,
} from "@mui/icons-material"

import {
	t,
	Trans,
} from "@lingui/macro"

import {
	ErrorDialog,
	ErrorResponse,
	RestResponse,
} from "@sinossi/mates-react-library"

import {
	LocalStorageEntries,
	SignatureInfo,
} from "common-models"
import withUseRouter, { UseRouterParams } from "services/router"
import { InternalError } from "services/error.service"
import { ReportProblemModal } from "components/sign"
import { ReportService } from "services/report.service"


interface ErrorProps extends UseRouterParams
{
}
interface ErrorState
{
	loading: boolean
	errorMessage: JSX.Element | string
	openReport: boolean
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
}
class Error extends React.Component<ErrorProps, ErrorState>
{
	protected token: string = LocalStorageEntries.readToken()!

	private signatureInfo: SignatureInfo|null = LocalStorageEntries.readSignatureInfo()

	constructor(props: ErrorProps)
	{
		super(props)
		this.state = {
			loading: false,
			errorMessage: "",
			openReport: false,
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}
	}

	public componentDidMount()
	{
		const state: any = this.props.location.state
		const errorCode: string|null = state?.errorCode
		const errorMessage = this.getErrorMessage(errorCode as InternalError|null)

		console.log(`Error code: ${errorCode}`)
		console.log(`Error message: ${errorMessage}`)

		this.setState(() => ({
			errorMessage: errorMessage,
		}))
	}

	private getErrorMessage = (errorCode?: InternalError|null): JSX.Element|string => {
		switch(errorCode) {
			case InternalError.INITIALIZATION_FAILED:
				return t({
					id: "error.initialization-failed.message",
					message: "Impossibile inizializzare il processo di firma.",
					comment: "Initialization failed error message",
				})
			case InternalError.NO_TOKEN_FOUND:
				return (
					<Trans
						id="erros.no-token-found.message"
						comment="Cannot read token from URL error modal message">
						Il link di invito non è valido.<br />Clicca sul link che hai ricevuto per email o assicurati di aver copiato per intero l'indirizzo.
					</Trans>
				)
			case InternalError.NO_SUCCESS_FOUND:
				return (
					<Trans
						id="errors.no-success-found.message"
						comment="Cannot read success from URL error message">
						Il link di invito non è valido.<br />Clicca sul link che hai ricevuto per email o assicurati di aver copiato per intero l'indirizzo.
					</Trans>
				)
			case InternalError.NO_FAILURE_FOUND:
				return (
					<Trans
						id="errors.no-failure-found.message"
						comment="Cannot read failure from URL error message">
						Il link di invito non è valido.<br />Clicca sul link che hai ricevuto per email o assicurati di aver copiato per intero l'indirizzo.
					</Trans>
				)
			case InternalError.NO_CANCEL_FOUND:
				return (
					<Trans
						id="errors.no-cancel-found.message"
						comment="Cannot read cancel from URL error message">
						Il link di invito non è valido.<br />Clicca sul link che hai ricevuto per email o assicurati di aver copiato per intero l'indirizzo.
					</Trans>
				)
			case InternalError.MISSING_REQUIRED_PARAMS:
				return (
					<Trans
						id="errors.missing-required-params.message"
						comment="Missing required params error message">
						Per poter accedere al processo di firma hai bisogno di un link di invito.<br />Clicca sul link che hai ricevuto per email o assicurati di aver copiato per intero l'indirizzo.
					</Trans>
				)
			case InternalError.PROCESS_ABORTED__NO_CALLBACK_URL:
				return t({
					id: "error.process-aborted_no-callback-url.message",
					message: "Il processo è stato interrotto, ora puoi chiudere questa finestra.",
				})
		}

		return t({
			id: "error.generic-error.message",
			message: "È avvenuto un errore imprevisto, impossibile procedere con il processo di firma.",
			comment: "Generic error message",
		})
	}

	private handleOpenReport = (): void => {
		this.setState(() => ({openReport: true}))
	}

	private handleCloseReport = (): void => {
		this.setState(() => ({openReport: false}))
	}

	private reportProblem = (problemDescription: string): void => {
		this.setState(() => ({
			loading: true,
			openReport: false,
		}))

		ReportService.sendReportEntry(
			this.token,
			problemDescription,
			this.onReportSent,
			this.onReportProblemError
		)
	}

	private onReportSent = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onReportProblemError()
			return
		}

		this.setState(() => ({
			loading: false,
			errorDialog: {
				open: true,
				title: "Grazie per la segnalazione", // FIXME: tradurre
				message: "Abbiamo ricevuto la tua segnalazione: un operatore la verificherà quanto prima. Grazie.", // FIXME: tradurre
			},
		}))
	}

	private onReportProblemError = (): void => {
		this.setState(() => ({
			loading: false,
			errorDialog: {
				open: true,
				title: t({
					id: "sign.report-problem-failed.title",
					message: "Errore",
					comment: "Title for report problem failed modal",
				}),
				message: t({
					id: "sign.report-problem-failed.message",
					message: "Non è stato possibile inviare la segnalazione, si prega di riprovare.",
					comment: "Message for report problem failed modal",
				}),
			},
		}))
	}

	private onErrorDialogClose = (): void => {
		this.setState(() => ({
			loading: false,
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}))
	}

	render()
	{
		return (
			<>
				<ErrorDialog
					ok={this.onErrorDialogClose}
					okText="OK"
					{...this.state.errorDialog} />
				<ReportProblemModal
					open={this.state.openReport}
					confirm={this.reportProblem}
					reject={this.handleCloseReport} />

				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Warning color="error" sx={{ fontSize: 120 }} />
					<Typography mt={2} mb={5} variant="h4" textAlign="center">
						{this.state.errorMessage}
					</Typography>

					{this.signatureInfo?.config?.errorReportsEnabled &&
						<Button
							variant="contained"
							startIcon={<ReportProblem />}
							onClick={this.handleOpenReport}>
							Segnala un problema
						</Button>
					}
				</Box>
			</>
		)
	}
}

export default withUseRouter(Error)
