import React from "react"

import {
	Box,
	Button,
	Typography,
} from "@mui/material"
import { ArrowBack } from "@mui/icons-material"

import { Trans } from "@lingui/macro"

import {
	ErrorResponse,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	ClauseStatus,
	LocalStorageEntries,
	SignatureData,
	SignatureInfo,
} from "common-models"
import { ErrorService } from "services/error.service"
import withUseRouter, { UseRouterParams } from "services/router"
import {
	ChangeSignatureModeModal,
	Clause,
	ConfirmCertificateEmissionModal,
	ConfirmFailedModal,
} from "components/sign"

import {
	AbortProcessMode,
	BaseStep,
	BaseStepProps,
	BaseStepState,
} from "./base-step"
import { ReportService } from "services/report.service"

interface ClausesStepProps extends BaseStepProps, UseRouterParams
{
}
interface ClausesStepState extends BaseStepState
{
	clauses: SignatureData[]
	showCertificateEmission: boolean
	showConfirmFailedModal: boolean
	openChangeSignature: boolean
	showChangeSignature: boolean
}
class ClausesStep extends BaseStep<ClausesStepProps, ClausesStepState>
{
	// private EMPTY_CLAUSE: SignatureData = SignatureData.empty()
	
	constructor(props: ClausesStepProps)
	{
		super(props)
		this.state = {
			clauses: LocalStorageEntries.readSignatureInfo()?.list ?? [],
			showCertificateEmission: false,
			showConfirmFailedModal: false,
			openChangeSignature: false,
			showChangeSignature: this.showChangeSignature(),
		}
	}

	public componentDidMount()
	{
		if(this.state.clauses.length === 0) {
			this.confirmCertificationEmission()
		}
	}

	// implemented from abstract parent
	public getStepTitle = (): React.ReactNode|string => {
		if(this.state.clauses.length === 0) {
			return (
				<Trans
					id="sign.step-clause.no-clauses-title"
					comment="step label clauses title">
					Preparazione firma
				</Trans>
			)
		}

		const mandatoryClausesCount: number = this.state.clauses
			.filter((it: SignatureData) => it.mandatory === true)
			.length
		return (
			<Box sx={{ display: "flex", flexDirection: "horizontal" }}>
				<Trans
					id="sign.step-clause"
					comment="step label clauses">
					Clausole Contrattuali
				</Trans>
				{mandatoryClausesCount > 0 &&
					<Typography variant="caption" sx={{ml: 3}}>
						<Typography variant="caption" color="error" component="span">*</Typography>
						<span> = </span>
						<Trans
							id="clause.mandatory-info"
							comment="Mandatory info">
							Accettazione Obbligatoria
						</Trans>
					</Typography>
				}
			</Box>
		)
	}

	// implemented from abstract parent
	public getStepContent = (): React.ReactNode => {
		return (
			<Box mt={2}>
				<ConfirmCertificateEmissionModal
					open={this.state.showCertificateEmission}
					confirm={this.confirmCertificationEmission}
					reject={() => this.abortProcess(AbortProcessMode.REQUIRE_CONFIRM)} />
				<ConfirmFailedModal
					open={this.state.showConfirmFailedModal}
					confirm={this.confirmCertificationEmission}
					reject={() => this.abortProcess(AbortProcessMode.REQUIRE_CONFIRM)} />
				<ChangeSignatureModeModal
					open={this.state.openChangeSignature}
					confirm={this.changeSignature}
					reject={this.closeChangeSignature} />
				{this.state.showChangeSignature && <Button
					type="button"
					variant="outlined"
					size="small"
					color="primary"
					startIcon={<ArrowBack fontSize="small" />}
					sx={{mb: 2}}
					onClick={this.handleOpenChangeSignature}>
					<Trans
						id="sign.otp-step.change-signature"
						comment="Button to go back to the previous page to change signature mode">
						Cambia modalità di firma
					</Trans>
				</Button>}
				{this.state.clauses.map((clause) =>
					<Clause
						key={clause.id}
						clause={clause}
						updateClause={this.updateClause}
						abortProcess={() => this.props.abortProcess(AbortProcessMode.NO_CONFIRM_NEEDED)} />
				)}
				{this.state.clauses.length === 0 && <>Preparazione firma in corso...</>}
			</Box>
		)
	}

	private sendClauseStatus = (clause: SignatureData): void => {
		this.props.showLoading()
		let url = `/api/process/${this.token}/clause/${clause.id}`

		switch(clause.status) {
			case ClauseStatus.PENDING:
				RestService.put<SignatureInfo>(url, null)
					.then(this.onUpdateClauseSuccess)
					.catch(this.onUpdateClauseFailure)
				ReportService.sendReportEntry(this.token, "Cancellata accettazione clausola: " + clause.name)
				break
			case ClauseStatus.REJECTED:
				RestService.delete<SignatureInfo>(url)
					.then(this.onUpdateClauseSuccess)
					.catch(this.onUpdateClauseFailure)
				ReportService.sendReportEntry(this.token, "Rifiutata clausola: " + clause.name)
				break
			case ClauseStatus.ACCEPTED:
				RestService.post<SignatureInfo>(url, null)
					.then(this.onUpdateClauseSuccess)
					.catch(this.onUpdateClauseFailure)
				ReportService.sendReportEntry(this.token, "Accettata clausola: " + clause.name)
				break
		}
	}

	private onUpdateClauseSuccess = (response: RestResponse<SignatureInfo, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onUpdateClauseFailure(response)
			return
		}

		this.props.hideLoading()
	}

	private onUpdateClauseFailure = (response: RestResponse<SignatureInfo, ErrorResponse>): void => {
		this.props.hideLoading()
		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	private updateClause = (clauseId: string, status: ClauseStatus): void => {
		if(clauseId === SignatureData.EMPTY_ID) {
			this.setState(() => ({ showCertificateEmission: true, }))
			return
		}
		
		let list: SignatureData[] = this.state.clauses
		let clauseIndex = list.findIndex((it) => it.id === clauseId)
		if(clauseIndex === -1) {
			return
		}

		list[clauseIndex].status = status
		this.setState(() => ({ clauses: list }))
		let clause = list[clauseIndex]
		this.sendClauseStatus(clause)

		this.checkIfCompleted(list)
	}

	private checkIfCompleted = (clauses: SignatureData[]): void => {
		let allClausesAccepted: boolean = clauses
			.filter((it: SignatureData) => it.mandatory === true)
			.every((it: SignatureData) => it.status === ClauseStatus.ACCEPTED)
		if(!allClausesAccepted) {
			return
		}

		this.setState(() => ({ showCertificateEmission: true, }))
	}

	private confirmCertificationEmission = (): void => {
		this.setState(() => ({
			showCertificateEmission: false,
			showConfirmFailedModal: false,
		}))

		this.props.showLoading()
		RestService
			.post<SignatureInfo>(`/api/process/${this.token}/signature`, null)
			.then(this.onConfirmSuccess)
			.catch(this.onConfirmError)
	}

	private onConfirmSuccess = (response: RestResponse<any, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onConfirmError()
			return
		}

		this.props.hideLoading()
		this.props.stepCompleted()
	}

	private onConfirmError = (): void => {
		this.props.hideLoading()
		this.setState(() => ({
			showConfirmFailedModal: true,
		}))
	}

	private showChangeSignature = (): boolean => {
		const signatureInfo: SignatureInfo = LocalStorageEntries.readSignatureInfo()!
		const modes = signatureInfo.config?.modes

		return modes?.length !== 1
	}

	private handleOpenChangeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Richiesto cambio modalità di firma")
		this.setState(() => ({openChangeSignature: true}))
	}

	private closeChangeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Annullato cambio modalità di firma")
		this.setState(() => ({openChangeSignature: false}))
	}

	private changeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Confermato cambio modalità di firma")
		this.setState(() => ({openChangeSignature: false}))
		this.props.showLoading()
		RestService
			.delete<void>(`/api/process/${this.token}/signature`)
			.then(this.onChangeSignatureSuccess)
			.catch(this.onChangeSignatureError)
	}

	private onChangeSignatureSuccess = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onChangeSignatureError(response)
			return
		}

		LocalStorageEntries.clearSignatureMode()

		let {navigate} = this.props
		navigate("/signature-selection")
	}

	private onChangeSignatureError = (response: RestResponse<void, ErrorResponse>): void => {
		this.props.hideLoading()
		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}
}

export default withUseRouter(ClausesStep)
