import React from "react"

import {
	Navigate,
	Route,
	Routes,
} from "react-router-dom"

import {
	LocalStorageEntries,
} from "common-models"
import { ChildrenComponentProp } from "services/utils"
import { InternalError } from "services/error.service"

import {
	Complete,
	Home,
	PdfViewer,
	Sign,
	SignatureModeSelection,
} from "pages"
import {
	PageNotFound,
	PageForbidden,
	Error,
} from "pages/errors"

class CheckLocalStorage extends React.Component<ChildrenComponentProp, {}>
{
	render()
	{
		let existsToken = LocalStorageEntries.existsToken()
		let existsSignatureInfo = LocalStorageEntries.existsSignatureInfo()
		let existsCallbackUrls = LocalStorageEntries.existsCallbackUrls()
		if(!existsToken ||
			!existsSignatureInfo ||
			!existsCallbackUrls) {
			return (
				<Navigate
					to="/error"
					replace
					state={{errorCode: InternalError.MISSING_REQUIRED_PARAMS}}
				/>
			)
		}

		return this.props.children
	}
}

const routes = () => (
	<Routes>
		<Route path="/" element={<Home />} />
		<Route path="/viewer" element={<CheckLocalStorage><PdfViewer /></CheckLocalStorage>} />
		<Route path="/signature-selection" element={<CheckLocalStorage><SignatureModeSelection /></CheckLocalStorage>} />
		<Route path="/sign" element={<CheckLocalStorage><Sign /></CheckLocalStorage>} />
		<Route path="/complete" element={<CheckLocalStorage><Complete /></CheckLocalStorage>} />
		<Route path="/error" element={<Error />} />
		<Route path="/forbidden" element={<PageForbidden />} />
		<Route path="*" element={<PageNotFound />} />
	</Routes>
)

export default routes
