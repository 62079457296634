import React from "react"

import {
	Box,
	Button,
	Paper,
	Typography,
} from "@mui/material"

import { Trans } from "@lingui/macro"

import {
	ErrorResponse,
	Loading,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	LocalStorageEntries,
	SignatureInfo,
	CallbackUrls,
	CoverType,
} from "common-models"
import { MuiWrapperContext } from "themes"
import withUseRouter, { UseRouterParams } from "services/router"
import {
	ErrorService,
	InternalError,
} from "services/error.service"
import { OtpForm } from "components/home/otp"

import { ReportService } from "services/report.service"

import SignImage from "./sign.svg"
import "./style.scss"

interface HomeProps extends UseRouterParams
{
}
interface HomeState
{
	loading: boolean
	signatureInfo: SignatureInfo | null
}
class Home extends React.Component<HomeProps, HomeState>
{
	static contextType = MuiWrapperContext
	context!: React.ContextType<typeof MuiWrapperContext>

	constructor(props: HomeProps)
	{
		super(props)
		this.state = {
			loading: true,
			signatureInfo: null,
		}
	}

	public componentDidMount()
	{
		const url: URL = new URL(window.location.href)

		const token: string|null = url.searchParams.get("token")
		if(token === null) {
			this.onError(InternalError.NO_TOKEN_FOUND)
			return
		}
		LocalStorageEntries.saveToken(token)
		ReportService.sendReportEntry(token, "Inizio processo di firma")

		const successUrl: string|null = url.searchParams.get("s")
		if(successUrl === null) {
			this.onError(InternalError.NO_SUCCESS_FOUND)
			return
		}
		const failureUrl: string|null = url.searchParams.get("f")
		if(failureUrl === null) {
			this.onError(InternalError.NO_FAILURE_FOUND)
			return
		}
		const cancelUrl: string|null = url.searchParams.get("c")
		if(cancelUrl === null) {
			this.onError(InternalError.NO_CANCEL_FOUND)
			return
		}

		let parameters = new CallbackUrls(successUrl, failureUrl, cancelUrl)
		parameters.decode()
		LocalStorageEntries.saveCallbackUrls(parameters)

		this.getData(token)
	}

	private getData = (token: string): void => {
		let url = `/api/process/${token}`
		RestService
			.get<SignatureInfo>(url)
			.then(this.setData)
			.catch(this.onGetDataError)
	}

	private setData = (response: RestResponse<SignatureInfo, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onError(InternalError.INITIALIZATION_FAILED)
			return
		}

		this.setState(() => ({
			loading: false,
			signatureInfo: response.data,
		}))
		LocalStorageEntries.saveSignatureInfo(response.data!)

		const theme: string = response.data?.config?.theme || ""
		this.context?.changeTheme(theme)
	}

	private onGetDataError = (): void => {
		this.onError(InternalError.INITIALIZATION_FAILED)
	}

	private onError = (errorCode: InternalError): void => {
		setTimeout(() => {
			let {navigate} = this.props
			ErrorService.openErrorPageWithInternalError(errorCode, navigate)
		})
	}

	private getOtpComponent = (): JSX.Element => {
		const cover: string = this.state.signatureInfo?.config?.cover || ""

		let {navigate} = this.props
		switch(cover) {
			case CoverType.OTP:
				return <OtpForm onOtpValidated={this.onOtpValidated} />
			case CoverType.DISABLED:
				navigate("/viewer")
				return <></>
			default:
				return (
					<Box display="flex" justifyContent="center">
						<Button
							onClick={() => { navigate("/viewer") }}
							variant="contained">
							<Trans
								id="home.go-to-document" comment="sends user to document">
								Vai al documento
							</Trans>
						</Button>
					</Box>
				)
		}
	}

	private onOtpValidated = (): void => {
		let {navigate} = this.props
		navigate("/viewer")
	}

	render()
	{
		const otpComponent = this.getOtpComponent()

		let label: JSX.Element = <></>
		const documentLabel: string = this.state.signatureInfo?.document?.label || ""
		if(documentLabel !== "") {
			label = (
				<>
					<span>&nbsp;</span>
					<Typography variant="inherit"
						color="primary"
						className="word-highlight">
						({this.state.signatureInfo?.document?.label})
					</Typography>
				</>
			)
		}

		return (
			<Box display="flex" flexDirection="row" alignItems="center" mt={5}>
				<Loading show={this.state.loading} />
				<Box component={Paper} padding={5} hidden={this.state.loading}>
					<Typography variant="h3" sx={{mb: 1}}>
						<Trans id="home.welcome-message" comment="Header home">
							Ciao
						</Trans>
						<span>&nbsp;</span>
						<Typography variant="h3" component="span" color="primary">
							{this.state.signatureInfo?.signer},
						</Typography>
					</Typography>

					<Typography variant="body1" id="message">
						<Trans id="home.sign-clause" comment="sign document clause">
							benvenuto nel servizio di Firma Elettronica Semplice che ti permetterà di sottoscrivere il
							documento&#32;
							<Typography variant="inherit" color="primary" component="span">
								{this.state.signatureInfo?.document?.fileName}{label}
							</Typography>.
						</Trans>
					</Typography>

					{this.state.signatureInfo?.sender !== null && this.state.signatureInfo?.sender !== this.state.signatureInfo?.signer &&
						<Typography variant="body1" id="message" sx={{mt: 2}}>
							<Trans id="home.invite" comment="invited to sign document">
								Sei stato invitato da&#32;
								<Typography variant="inherit" color="primary" className="word-highlight">
									{this.state.signatureInfo?.sender}
								</Typography>.
							</Trans>
						</Typography>}

					<Box sx={{mt: 3}}>{otpComponent}</Box>
				</Box>

				<Box
					component="img"
					src={SignImage}
					maxWidth="40vw"
					hidden={this.state.loading}
					title="Business illustrations by Storyset https://storyset.com/business" />
			</Box>
		)
	}
}

export default withUseRouter(Home)
