import React from "react"

import {
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	styled,
	Button,
} from "@mui/material"
import HelpIcon from "@mui/icons-material/Help"
import {
	Close,
	Download,
} from "@mui/icons-material"

import { Trans } from "@lingui/macro"

import {
	AuthenticatedDownload,
	ErrorDialog,
} from "@sinossi/mates-react-library"

import {
	CallbackUrls,
	LocalStorageEntries,
} from "common-models"
import withUseRouter, { UseRouterParams } from "services/router"
import {
	ErrorService,
	InternalError,
} from "services/error.service"
import { AbortProcessModal } from "components/abort-process-modal/abort-process-modal"

interface StyledAppBarProps
{
	drawerOpen: boolean
	drawerWidth: number
}
const StyledAppBar = styled(AppBar, { shouldForwardProp: () => true, })<StyledAppBarProps>
(({ theme, drawerOpen, drawerWidth }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(drawerOpen && {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	}),
}))

interface HeaderProps extends UseRouterParams
{
	openCloseDrawer: () => void
	drawerOpen: boolean
	drawerWidth: number
}
interface HeaderState
{
	openAbortProcessModal: boolean
	genericErrorDialog: {
		open: boolean
		title: string
		message: string
	}
}
class Header extends React.Component<HeaderProps, HeaderState>
{
	private url: URL = new URL(window.location.href)
	private token: string|null = LocalStorageEntries.readToken() || this.url.searchParams.get("token")

	constructor(props: HeaderProps)
	{
		super(props)
		this.state = {
			openAbortProcessModal: false,
			genericErrorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}
	}

	private terminateProcess = (): void => {
		this.setState(() => ({ openAbortProcessModal: true, }))
	}

	private onProcessAborted = (): void => {
		this.setState(() => ({ openAbortProcessModal: false, }))
		const callbackUrls: CallbackUrls|null = LocalStorageEntries.readCallbackUrls()
		if(callbackUrls === null) {
			const { navigate } = this.props
			ErrorService.openErrorPageWithInternalError(InternalError.PROCESS_ABORTED__NO_CALLBACK_URL, navigate)
			return
		}

		window.location.href = callbackUrls.cancelUrl
	}

	private onProcessAbortCancelled = (): void => {
		this.setState(() => ({ openAbortProcessModal: false, }))
	}

	private onProcessAbortFailed = (): void => {
		this.setState(() => ({
			openAbortProcessModal: false,
			genericErrorDialog: {
				open: false,
				title: "Errore", // TODO: translate
				message: "Non è stato possibile procedere con l'interruzione del processo. Si prega di riprovare.", // TODO: translate
			}
		}))
	}

	private onCloseErrorDialog = (): void => {
		this.setState(() => ({
			genericErrorDialog: {
				open: false,
				title: "",
				message: "",
			}
		}))
	}

	render()
	{
		return (
			<>
				<ErrorDialog
					{...this.state.genericErrorDialog}
					ok={this.onCloseErrorDialog} />
				<AbortProcessModal
					openModal={this.state.openAbortProcessModal}
					onConfirm={this.onProcessAborted}
					onCancel={this.onProcessAbortCancelled}
					onFailure={this.onProcessAbortFailed} />

				<StyledAppBar
					position="fixed"
					drawerOpen={this.props.drawerOpen}
					drawerWidth={this.props.drawerWidth}>
					<Toolbar>
						<Typography variant="h6" component="div">
							Mates TEMI - <small>La firma, semplice.</small>
						</Typography>
						<Typography sx={{flexGrow: 1}} />

						{this.token !== null &&
							this.props.location.pathname !== "/" &&
							this.props.location.pathname !== "/error" &&
							this.props.location.pathname !== "/complete" &&
							<>
								<Button
									variant="contained"
									onClick={this.terminateProcess}
									startIcon={<Close />}
									color="warning"
									sx={{mr: 3}}>
									Annulla il processo
								</Button>
								<AuthenticatedDownload
									url={`/api/process/get-document/${this.token!}?mode=DOWNLOAD`}>
									<Button
										variant="contained"
										color="neutralSecondary"
										startIcon={<Download />}
										sx={{mr: 3}}>
										<Trans
											id="otp-sign-download"
											comment="document download">
											SCARICA DOCUMENTO
										</Trans>
									</Button>
								</AuthenticatedDownload>
							</>
						}


						{!this.props.drawerOpen && <IconButton
							color="inherit"
							onClick={this.props.openCloseDrawer}>
							<HelpIcon />
						</IconButton>}
					</Toolbar>
				</StyledAppBar>
			</>
		)
	}
}

export default withUseRouter(Header)
