import { ThemeOptions } from "@mui/material"

import "./theme-config"

import DefaultTheme from "./default-theme"
import IdoxTheme from "./idox"

export * from "./theme-wrapper"

export { DefaultTheme }

const themes: Record<string, ThemeOptions> = {
	"idox": IdoxTheme,
}
export default themes
