export enum ClauseStatus
{
	PENDING = "PENDING", //
	ACCEPTED = "ACCEPTED", //
	REJECTED = "REJECTED", //
}
export enum SignatureMode
{
	INTERNAL = "INTERNAL",
	DIGITAL_EXTERNAL = "DIGITAL_EXTERNAL",
	MANUAL = "MANUAL",
}
export enum CoverType
{
	DISABLED = "DISABLED",
	SIMPLE = "SIMPLE",
	OTP = "OTP",
}


export class LocalStorageEntries
{
	private static readonly TOKEN_KEY = "token"
	private static readonly SIGNATURE_INFO_KEY = "signature-info"
	private static readonly SIGNATURE_MODE = "signature-mode"
	private static readonly CALLBACK_URLS = "callback-urls"

	private constructor()
	{
	}

	public static clear = (): void => {
		localStorage.removeItem(LocalStorageEntries.TOKEN_KEY)
		localStorage.removeItem(LocalStorageEntries.SIGNATURE_INFO_KEY)
		localStorage.removeItem(LocalStorageEntries.SIGNATURE_MODE)
		localStorage.removeItem(LocalStorageEntries.CALLBACK_URLS)
	}

	public static saveToken = (token: string): void => {
		localStorage.setItem(LocalStorageEntries.TOKEN_KEY, token)
	}

	public static readToken = (): string | null => {
		return localStorage.getItem(LocalStorageEntries.TOKEN_KEY)
	}

	public static existsToken = (): boolean => {
		return LocalStorageEntries.readToken() !== null
	}

	public static saveSignatureInfo = (info: SignatureInfo): void => {
		localStorage.setItem(LocalStorageEntries.SIGNATURE_INFO_KEY, JSON.stringify(info))
	}

	public static readSignatureInfo = (): SignatureInfo | null => {
		const info = localStorage.getItem(LocalStorageEntries.SIGNATURE_INFO_KEY)
		if(info === null) {
			return null
		}

		const parsed = JSON.parse(info) || null
		return parsed as SignatureInfo | null
	}

	public static existsSignatureInfo = (): boolean => {
		const data = localStorage.getItem(LocalStorageEntries.SIGNATURE_INFO_KEY)
		return data !== null
	}

	public static saveSignatureMode = (signMode: SignatureMode): void => {
		localStorage.setItem(LocalStorageEntries.SIGNATURE_MODE, signMode)
	}

	public static readSignatureMode = (): SignatureMode | null => {
		const signMode: string|null = localStorage.getItem(LocalStorageEntries.SIGNATURE_MODE)
		if(signMode === null) {
			return null
		}
		if(!Object.values<string>(SignatureMode).includes(signMode)) {
			return null
		}

		return SignatureMode[signMode! as keyof typeof SignatureMode]
	}

	public static clearSignatureMode = () : void => {
		localStorage.removeItem(LocalStorageEntries.SIGNATURE_MODE)
	}

	public static existsSignatureMode = (): boolean => {
		return LocalStorageEntries.readSignatureMode() !== null
	}

	public static saveCallbackUrls = (callbackUrls: CallbackUrls): void => {
		localStorage.setItem(LocalStorageEntries.CALLBACK_URLS, JSON.stringify(callbackUrls))
	}

	public static readCallbackUrls = (): CallbackUrls | null => {
		const info = localStorage.getItem(LocalStorageEntries.CALLBACK_URLS)
		if(info === null) {
			return null
		}

		const parsed = JSON.parse(info) || null
		return parsed as CallbackUrls | null
	}

	public static existsCallbackUrls = (): boolean => {
		const data = localStorage.getItem(LocalStorageEntries.CALLBACK_URLS)
		return data !== null
	}
}

export class SignatureData
{
	static EMPTY_ID: string = "__EMPTY__"

	id?: string | null
	box?: Box | null
	clause?: string | null
	mandatory?: boolean | null
	name?: string | null
	page?: number | null
	status?: ClauseStatus | null

	static empty(): SignatureData
	{
		let result = new SignatureData()
		result.id = SignatureData.EMPTY_ID
		result.clause = null
		result.mandatory = true
		result.name = "Approvo le clausole indicate nel contratto"
		result.page = -1
		result.status = ClauseStatus.PENDING

		return result
	}
}

export class SignatureInfo
{
	list?: SignatureData[] | null
	document?: Document | null
	config?: SignatureInfoConfig | null
	sender?: string | null
	signer?: string | null
}

export class Box
{
	height?: number | null
	width?: number | null
	x?: number | null
	y?: number | null
}

export class Document
{
	fileName?: string | null
	label?: string | null
}

export class SignatureInfoConfig
{
	cover?: CoverType | null
	modes?: SignatureMode[] | null
	errorReportsEnabled?: boolean | null
	continueRequireScroll?: boolean | null
	theme?: string | null
	translations?: Record<string, Record<string, string>> | null
}

export class CallbackUrls
{
	constructor(public successUrl: string, public failureUrl: string, public cancelUrl: string)
	{
	}

	decode()
	{
		this.successUrl = window.atob(this.successUrl)
		this.failureUrl = window.atob(this.failureUrl)
		this.cancelUrl = window.atob(this.cancelUrl)
	}
}
