import React, {
	ChangeEvent,
	DragEvent,
} from "react"

import {
	Box,
	Button,
	LinearProgress,
	Paper,
	Typography,
} from "@mui/material"
import { withTheme, WithTheme } from "@mui/styles"
import {
	ArrowBack,
	CloudUpload,
} from "@mui/icons-material"

import {
	t,
	Trans,
} from "@lingui/macro"

import {
	ErrorResponse,
	NetworkParams,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	LocalStorageEntries,
	SignatureInfo,
	SignatureMode,
} from "common-models"
import {
	ErrorService,
	ServerErrorCodes,
} from "services/error.service"
import withUseRouter, { UseRouterParams } from "services/router"

import { ChangeSignatureModeModal } from "../modals"
import {
	BaseStep,
	BaseStepProps,
	BaseStepState,
} from "./base-step"

import PdfImage from "./pdf.png"
import { ReportService } from "services/report.service"

interface UploadStepProps extends BaseStepProps, UseRouterParams, WithTheme<any>
{
}
interface UploadStepState extends BaseStepState
{
	fileInDropZone: boolean
	file: File|null
	uploading: boolean
	openChangeSignature: boolean
	showChangeSignature: boolean
	errorDialog: {
		open: boolean
		title: string
		message: string
	}
}
class UploadStep extends BaseStep<UploadStepProps, UploadStepState>
{
	private uploadRef: React.RefObject<any> = React.createRef()

	private signatureMode: SignatureMode = LocalStorageEntries.readSignatureMode()!

	constructor(props: UploadStepProps)
	{
		super(props)
		const currentState = this.state || {}
		this.state = {
			...currentState,
			fileInDropZone: false,
			file: null,
			uploading: false,
			openChangeSignature: false,
			showChangeSignature: this.showChangeSignature(),
			errorDialog: {
				open: false,
				title: "",
				message: "",
			},
		}
	}

	public getStepTitle(): string
	{
		return t({
			id: "sign.document-upload",
			comment: "step label document upload",
			message: "Carica il documento firmato",
		})
	}

	private setFile = (file: File): void => {
		this.setState(() => ({
			uploading: true,
			file: file,
		}))

		let request = {
			"otp": null,
			"mode": LocalStorageEntries.readSignatureMode()!,
		}
		let body: FormData = new FormData()
		body.append(
			"request",
			new Blob([ JSON.stringify(request) ], { type: "application/json" }),
			""
		)
		body.append("file", file)

		ReportService.sendReportEntry(this.token, "Caricato documento firmato")

		const params = new NetworkParams()
		params.contentType = undefined
		RestService
			.genericPut<void, ErrorResponse>(`/api/process/${this.token}/signature`, body, params)
			.then(this.onSignSuccess)
			.catch(this.onSignError)
	}

	private onSignSuccess = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onSignError(response)
			return
		}

		ReportService.sendReportEntry(this.token, "Documento firmato caricato con successo")

		this.setState(() => ({ uploading: false, }))
		const { navigate } = this.props
		navigate("/complete")
	}

	protected onSignError = (response: RestResponse<void, ErrorResponse>): void => {
		this.setState(() => ({
			uploading: false,
		}))

		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		if(errorCodeFromServer === ServerErrorCodes.GENERATION_ERROR) {
			ReportService.sendReportEntry(this.token, "Caricamento documento fallito")
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: t({
						id: "sign.signature-generic-error.title",
						message: "Operazione fallita",
						comment: "Title for signature generic error modal",
					}),
					message: t({
						id: "sign.signature-generic-error.message",
						message: "È avvenuto un errore imprevisto. Ti preghiamo di riprovare.",
						comment: "Message for signature generic error modal",
					}),
				}
			}))
			return
		} else if(errorCodeFromServer === ServerErrorCodes.SIGN_ERROR) {
			ReportService.sendReportEntry(this.token, "Il documento non è firmato o è firmato solo parzialmente")
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: t({
						id: "sign.signature-failed-error.title",
						message: "Operazione fallita",
						comment: "Title for signature failed error modal",
					}),
					message: t({
						id: "sign.signature-failed-error.message",
						message: "Uno o più campi firma non stati firmati correttamente. Verifica di aver firmato tutti i campi previsti.",
						comment: "Message for signature failed error modal",
					}),
				}
			}))
			return
		} else if(errorCodeFromServer === ServerErrorCodes.SIGNATURE_NOT_VALID) {
			ReportService.sendReportEntry(this.token, "Una o più firme non sono valide")
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: t({
						id: "sign.signature-not-valid-error.title",
						message: "Errore",
						comment: "Title for signature not valid error modal",
					}),
					message: t({
						id: "sign.signature-not-valid-error.message",
						message: "Il documento caricato contiene una o più firme non valide. Verifica di aver firmato con una credenziale qualificata.",
						comment: "Message for signature not valid error modal",
					}),
				}
			}))
			return
		} else if(errorCodeFromServer === ServerErrorCodes.NO_SIGNATURES_FOUND) {
			ReportService.sendReportEntry(this.token, "Nessuna firma trovata nel documento")
			this.setState(() => ({
				errorDialog: {
					open: true,
					title: t({
						id: "sign.no-signatures-found-error.title",
						message: "Errore",
						comment: "Title for no signatures found error modal",
					}),
					message: t({
						id: "sign.no-signatures-found-error.message",
						message: "Il documento caricato non è stato firmato. Verifica di aver inviato il documento corretto.",
						comment: "Message for no signatures found error modal",
					}),
				}
			}))
			return
		}

		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	private dropHandler = (event: DragEvent<HTMLDivElement>): void => {
		event.preventDefault()
		const items = event.dataTransfer.items
		if(items === null || items === undefined) {
			// TODO: show error
			return
		}
		if(items.length === 0 || items[0].kind !== "file") {
			// TODO: show error
			return
		}

		//todo:check file type
		let file = items[0].getAsFile()!
		this.setFile(file)
	}

	private dragOverHandler = (event: DragEvent<HTMLDivElement>): void => {
		event.preventDefault()
		this.setState(() => ({
			fileInDropZone: true,
		}))
	}

	private dragExitHandler = (event: DragEvent<HTMLDivElement>): void => {
		event.preventDefault()
		this.setState(() => ({
			fileInDropZone: false,
		}))
	}

	private updateFile = (event: ChangeEvent<HTMLInputElement>): void => {
		let files = event.target.files
		this.setFile(files![0])
	}

	private showChangeSignature = (): boolean => {
		const signatureInfo: SignatureInfo = LocalStorageEntries.readSignatureInfo()!
		const modes = signatureInfo.config?.modes

		return modes?.length !== 1
	}

	private handleOpenChangeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Richiesto cambio modalità di firma")
		this.setState(() => ({openChangeSignature: true}))
	}

	private closeChangeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Annullato cambio modalità di firma")
		this.setState(() => ({openChangeSignature: false}))
	}

	private changeSignature = (): void => {
		ReportService.sendReportEntry(this.token, "Confermato cambio modalità di firma")
		this.setState(() => ({openChangeSignature: false}))
		this.props.showLoading()
		RestService
			.delete<void>(`/api/process/${this.token}/signature`)
			.then(this.onChangeSignatureSuccess)
			.catch(this.onChangeSignatureError)
	}

	private onChangeSignatureSuccess = (response: RestResponse<any, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onChangeSignatureError(response)
			return
		}

		LocalStorageEntries.clearSignatureMode()
		const {navigate} = this.props
		navigate("/signature-selection")
	}

	private onChangeSignatureError = (response: RestResponse<any, ErrorResponse>): void => {
		this.props.hideLoading()
		const errorCodeFromServer = response?.error?.errorCode || response?.status() || 0
		const {navigate} = this.props
		ErrorService.openErrorPage(errorCodeFromServer, navigate)
	}

	public getStepContent(): React.ReactNode
	{
		return (
			<>
				<ChangeSignatureModeModal
					open={this.state.openChangeSignature}
					confirm={this.changeSignature}
					reject={this.closeChangeSignature} />
				{this.state.showChangeSignature && <Button
					type="button"
					variant="outlined"
					size="small"
					color="primary"
					startIcon={<ArrowBack fontSize="small" />}
					sx={{mb: 2}}
					onClick={this.handleOpenChangeSignature}>
					<Trans
						id="sign.otp-step.change-signature"
						comment="Button to go back to the previous page to change signature mode">
						Cambia modalità di firma
					</Trans>
				</Button>}
				<Typography variant="h6" color="primary" textAlign="center" sx={{mt: 1, mb: 2}}>
					{this.signatureMode === SignatureMode.DIGITAL_EXTERNAL &&
						<Trans id="upload-step.external-digital-signature.title" comment="Title for upload step, external digital signature">
							Firma, con il software fornito dal tuo provider, il documento che hai appena scaricato e ricaricalo firmato qui sotto.
						</Trans>
					}
					{this.signatureMode === SignatureMode.MANUAL &&
						<Trans id="upload-step.manual-signature.title" comment="Title for upload step, manual signature">
							Stampa il documento che hai appena scaricato ed apponi la tua firma autografa ben leggibile.<br/>
							Scannerizza (o fotografa) il documento e ricaricalo qui sotto.
						</Trans>
					}
				</Typography>

				{!this.state.uploading &&
					<Box
						sx={{
							borderStyle: "dashed",
							borderWidth: this.state.fileInDropZone ? "5px" : "3px",
							borderColor: this.state.fileInDropZone ? this.props.theme.palette.primary.main : "#D8D8D8",
							backgroundColor: "#FFFFFF",
							p: 3,
							textAlign: "center"
						}}
						onDrop={this.dropHandler}
						onDragOver={this.dragOverHandler}
						onDragExit={this.dragExitHandler}>
							<CloudUpload
								onClick={() => this.uploadRef.current?.click()}
								color="primary"
								style={{ fontSize: "8em", cursor: "pointer" }} />
							<div>
								<Trans
									id="upload.message"
									comment="Drag and drop message">
									Trascina qui il file firmato o
								</Trans>
								<span>&nbsp;</span>
								<label htmlFor="upload" style={{display: "inline-block", cursor: "pointer"}}>
									<Typography
										color="primary">
										<Trans
											id="upload.message-reset"
											comment="Reset upload">
											Sfoglia...
										</Trans>
									</Typography>
									<input
										hidden
										id="upload"
										type="file"
										accept={(".pdf, .p7m")}
										ref={this.uploadRef}
										onChange={this.updateFile} />
								</label>
							</div>
					</Box>
				}

				{this.state.uploading &&
					<Paper
						elevation={2}
						sx={{
							mt: 2,
							p: 2,
							display: "flex",
							flexDirection: "horizontal",
						}}>
						<img src={PdfImage} style={{ height: "2.5em", marginRight: "16px" }} alt="" />
						<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
							<Typography variant="body2" sx={{mb: 1}}>
								{this.state.file!.name}
							</Typography>
							<LinearProgress color="primary" />
						</Box>
					</Paper>
				}
			</>
		)
	}
}

export default withUseRouter(withTheme(UploadStep))
