import React from "react"

import {
	Container,
	styled,
} from "@mui/material"

import Routes from "routes"

import StyledDrawerHeader from "structure/header/drawer-header"

interface StyledAppBarProps
{
	drawerOpen: boolean
	drawerWidth: number
}
const StyledMain = styled('main', { shouldForwardProp: () => true })<StyledAppBarProps>
(({ theme, drawerOpen, drawerWidth }) => ({
	height: "100vh",
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginRight: -drawerWidth, ...(drawerOpen && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	}),
}),)

interface MainProps
{
	drawerOpen: boolean
	drawerWidth: number
}
export class Main extends React.Component<MainProps, {}>
{
	constructor(props: MainProps)
	{
		super(props)
		this.state = {
		}
	}

	render()
	{
		return (
			<StyledMain {...this.props}>
				<StyledDrawerHeader />

				<Container maxWidth="xl">
					<Routes />
				</Container>
			</StyledMain>
		)
	}
}
