import React from "react"

import {
	PageChangeEvent,
	RenderPage,
	RenderPageProps,
	SpecialZoomLevel,
	Viewer,
} from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"

import {
	defaultLayoutPlugin,
	ToolbarProps,
} from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"

import type {
	ToolbarSlot,
	TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar"

import {
	HighlightArea,
	highlightPlugin,
	RenderHighlightsProps,
	Trigger,
} from "@react-pdf-viewer/highlight"
import "@react-pdf-viewer/highlight/lib/styles/index.css"

import "./pdfviewer-highlight.scss"
import {
	Box,
	useTheme,
} from "@mui/material"

interface RenderHighlightAreasExampleProps
{
	areas: HighlightArea[]
	fileUrl: string
	onScroll: any
}

const PdfViewerHighlight: React.FC<RenderHighlightAreasExampleProps> = ({
	areas,
	fileUrl,
	onScroll,
}) => {
	const theme = useTheme()

	const renderPage: RenderPage = (props: RenderPageProps) => (
		<>
			{props.canvasLayer.children}

			{areas
				.filter((area) => area.pageIndex === props.pageIndex)
				.map((area, idx) => (
					<div className="signature-indicator-container" key={`signature-indicator-${idx}`}>
						<div className="signature-indicator">
							<Box
								component="div"
								className="signature-indicator-icon"
								sx={{
									marginTop: (area.top * props.height / 100) + "px",
									backgroundColor: theme.palette.primary.main,
									color: theme.palette.primary.contrastText,
									"&:before": {
										borderRightColor: theme.palette.primary.main,
									},
								}}>
								🖉
							</Box>
						</div>
					</div>
				))}

			{props.annotationLayer.children}
			{props.textLayer.children}
		</>
	)

	const renderHighlights = (props: RenderHighlightsProps) => (
		<div>
			{areas
				.filter((area) => area.pageIndex === props.pageIndex)
				.map((area, idx) => (
					<div
						key={idx}
						className="highlight-area"
						style={Object.assign(
							{},
							{ backgroundColor: theme.palette.primary.main, },
							props.getCssProperties(area, props.rotation)
						)}
					>
						<svg className="highlight-text">
							<text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">🖉</text>
						</svg>
					</div>
				))}
		</div>
	)

	const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
		...slot, // These slots will be empty
		Open: () => <></>,
	})

	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar: (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
			<Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
		),
		sidebarTabs: (defaultTabs) => [ ],
	})
	const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance
	const highlightPluginInstance = highlightPlugin({
		renderHighlights,
		trigger: Trigger.None,
	})

	const handleLastPage = (e: PageChangeEvent) => {
		if(e.currentPage === e.doc.numPages - 1) {
			onScroll()
		}
	}

	return <Viewer
		theme="dark"
		defaultScale={SpecialZoomLevel.PageWidth}
		fileUrl={fileUrl}
		renderPage={renderPage}
		plugins={[
			defaultLayoutPluginInstance,
			highlightPluginInstance,
		]}
		onPageChange={(e) => handleLastPage(e)}
	/>
}

export default PdfViewerHighlight
