import React from "react"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material"

import { Trans } from "@lingui/macro"

interface ConfirmFailedModalProps
{
	open: boolean
	confirm: () => void
	reject: () => void
}
export class ConfirmFailedModal extends React.Component<ConfirmFailedModalProps, {}>
{
	render()
	{
		return (
			<Dialog
				open={this.props.open}
				onClose={() => false}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle>
					<Trans
						id="sign.modals.confirm-failed.title"
						comment="Certificate emission alert">
						Preparazione firma documento fallita
					</Trans>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Trans
							id="sign.modals.confirm-failed.message"
							comment="Certificate emission failed alert message">
							È avvenuto un errore durante la configurazione della firma o durante l'invio del messaggio
							SMS contenente l'OTP. Ti preghiamo di riprovare.
						</Trans>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="warning" onClick={this.props.reject}>
						<Trans
							id="sign.modals.confirm-failed.reject"
							comment="Certificate emission alert reject">
							Annulla il processo
						</Trans>
					</Button>
					<Button variant="contained" color="secondary" onClick={this.props.confirm}>
						<Trans
							id="sign.modals.confirm-failed.retry"
							comment="Certificate emission alert confirmation">
							Riprova
						</Trans>
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}
