import React from "react"

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material"

import { Trans } from "@lingui/macro"

import {
	ErrorResponse,
	Loading,
	RestResponse,
	RestService,
} from "@sinossi/mates-react-library"

import {
	LocalStorageEntries,
	SignatureInfo,
} from "common-models"

interface AbortProcessModalProps
{
	openModal: boolean
	onConfirm: () => void
	onCancel: () => void
	onFailure: () => void
}
interface AbortProcessModalState
{
	loading: boolean
}
export class AbortProcessModal extends React.Component<AbortProcessModalProps, AbortProcessModalState>
{
	constructor(props: AbortProcessModalProps)
	{
		super(props)
		this.state = {
			loading: false,
		}
	}

	private onConfirm = (): void => {
		this.setState(() => ({ loading: true, }))
		let url = `/api/process/${LocalStorageEntries.readToken()}`
		RestService
			.delete<SignatureInfo>(url)
			.then(this.onProcessAbortSuccess)
			.catch(this.onProcessAbortFailure)
	}

	private onProcessAbortSuccess = (response: RestResponse<void, ErrorResponse>): void => {
		if(response.hasError()) {
			this.onProcessAbortFailure()
			return
		}

		this.setState(() => ({ loading: false, }))
		this.props.onConfirm()
	}

	private onProcessAbortFailure = (): void => {
		this.setState(() => ({ loading: false, }))
		this.props.onFailure()
	}

	render()
	{
		return (
			<>
				<Loading show={this.state.loading} />
				<Dialog
					open={this.props.openModal && !this.state.loading}
					fullWidth={true}
					maxWidth="sm"
				>
					<DialogTitle id="alert-dialog-title">
						<Trans id="abort-process-modal.title">Attenzione</Trans>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<Trans
								id="abort-process-modal.confirm-text"
								comment="Descriptive text on abort-process-modal informing user about process abortion">
								Il processo verrà interrotto in via definitiva e
								non potrai più accedere a questa sessione di firma: dovrai quindi richiedere un nuovo invito.<br /><br />
								Sei sicuro di voler interrompere?
							</Trans>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Box textAlign={"right"}>
							<Button sx={{mr: 1}} variant="contained" color="secondary" onClick={this.props.onCancel}>
								<Trans
									id="abort-process-modal.cancel-button"
									comment="Cancel text for abort process modal">
									No, voglio proseguire
								</Trans>
							</Button>
							<Button variant="contained" color="error" onClick={this.onConfirm}>
								<Trans
									id="abort-process-modal.confirm-button"
									comment="Confirm text for abort process modal">
									Sì, voglio interrompere
								</Trans>
							</Button>
						</Box>
					</DialogActions>
				</Dialog>
			</>
		)
	}
}
